import * as Sentry from "@sentry/react";
import moment from "moment";
import Setting from "../../Utility/Setting";

class Api {
    static baseUrls = {
        rehabmedical: "https://api.rehabmedical-tech.com/api",
        corkmedical: "https://corkapi.rehabmedical-tech.com/api",
        uat: "https://test1.salespilot.com/api/api",
        localhost: "https://localhost:44384/api"
    }

    getOptions(method, body) {
        var options = {
            "method": method,
            "headers": {
                "Authorization": `Session ${Setting.get("session", true, window.sessionStorage).guid}`
            }
        };

        if (method === "put" || method === "post") {
            options.headers["Accept"] = "application/json, text/plain, */*";
            options.headers["Content-Type"] = "application/json";
            if (body != null && body !== undefined) {
                options.body = JSON.stringify(body);
            }
        }
        return options;
    }

    buildDownloadConfig(method) {
        var options = {
            "method": method,
            "headers": {
                "Authorization": `Session ${Setting.get("session", true, window.sessionStorage).guid}`,
                "Accept": "application/json, text/plain, */*"
            }
        };

        var formData = new FormData();
        var fileField = document.querySelector("input[type='file']");
        formData.append("file", fileField.files[0]);
        options.body = formData;

        return options;
    }

    /**
     * Send an API call.
     *
     * @param {string} method HTTP Method (GET, POST, PUT, etc)
     * @param {*} endpoint Endpoint to hit.
     * @param {*} parameters Optional parameters.
     * @param {number} retries Number of retries.
     */
    async send(method, endpoint, parameters, retries = 2) {
        let resource = `${Api.baseUrls[Setting.get("organization", true, window.sessionStorage)]}${endpoint}`;
        const options = {
            "method": method,
            "headers": {
                "Authorization": `Session ${Setting.get("session", true, window.sessionStorage).guid}`
            }
        };

        switch (method) {
            case "GET":
                if (parameters) {
                    resource = `${resource}?${new window.URLSearchParams(parameters).toString()}`;
                }
                break;
            case "POST":
            case "PUT":
                options.body = JSON.stringify(parameters || {});
                options.headers["Accept"] = "application/json, text/plain, */*";
                options.headers["Content-Type"] = "application/json";
                break;
        }

        try {
            const response = await fetch(resource, options);
            if (response.status >= 200 && response.status < 300) {
                const responseJson = await response.json();

                // Abstract away the success/data portion for newer API calls.
                if ("success" in responseJson) {
                    if (responseJson.success === false) {
                        throw new Error(`${response.status} - ${response.statusText} - Success false`);
                    } else {
                        return responseJson.data;
                    }
                } else {
                    return responseJson;
                }
            }
            if (response.status === 401) {
                if (window.location.pathname !== "/") {
                    // If unauthorized and not on the login screen, go there.
                    Setting.clear("session", window.sessionStorage);
                    window.location.href = "/";
                    return null;
                }
            } else {
                // Any other response
                throw new Error(`${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            const newError = new Error(`${method} ${resource} failed (${error.message})`);
            if (retries > 0) {
                console.error(newError);
                // Wait a full second before retrying.
                await new Promise(response => setTimeout(response, 1000));
                console.error(`Retrying request (${retries - 1} remaining retries after this).`);
                return await api.send(method, endpoint, parameters, retries - 1);
            } else {
                console.error(newError);
                Sentry.captureException(newError, { fingerprint: ['apiError'] });
                throw newError;
            }
        }
    }

    async get(endpoint, params) {
        return new Promise((resolve, reject) => {
            let resource = `${Api.baseUrls[Setting.get("organization", true, window.sessionStorage)]}${endpoint}`;

            if (params) {
                resource = `${resource}?${new window.URLSearchParams(params).toString()}`;
            }

            let options = this.getOptions("get");

            Setting.set("meta.lastActivity", moment().toISOString());
            fetch(resource, options)
                .then((data) => {
                    if (data.status >= 200 && data.status < 300) {
                        // Success; resolve with the JSON
                        resolve(data.json());
                    } else {
                        // Failure; handle appropriately
                        if (data.status === 401) {
                            if (window.location.pathname !== "/") {
                                // If unauthorized and not on the login screen,
                                // go there.
                                Setting.clear("session", window.sessionStorage);
                                window.location.href = "/";
                                reject("Unauthorized");
                            }
                        } else {
                            // If not unauthorized; just some other issue (ex:
                            // internal server error or 404) log the failure and
                            // reject.
                            const newError = new Error(`GET ${resource} failed [${data.status}]`);
                            console.error(newError);
                            Sentry.captureException(
                                newError,
                                { fingerprint: ['fetchError'] }
                            );
                            reject(data);
                        }
                    }
                })
                .catch((error) => {
                    const newError = new Error(`GET ${resource} failed (${error.message})`);
                    console.error(newError);
                    Sentry.captureException(
                        newError,
                        { fingerprint: ['fetchError'] }
                    );
                    reject("error");
                });
        });
    }


    /**
     * This will be the future of API calls. Simpler than all the other stuff.
     * At present it's only used for the initial authorize calls.
     *
     * @param {string} endpoint The endpoint
     * @param {object} body An object that will be JSON encoded and sent as part
     * of the request.
     */
    async post2(endpoint, body) {
        // TODO: If we move everything over to this, the /token API call needs
        // to get the base URL from localStorage. Everything else needs to use
        // sessionStorage.
        const resource = `${Api.baseUrls[Setting.get("organization", true, window.localStorage)]}${endpoint}`

        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "Authorization": "Session x",
                // TODO: If session guid add it here
            },
            body: JSON.stringify(body)
        }

        return new Promise((resolve, reject) => {
            fetch(resource, options)
                .then((response) => response.json().catch(() => {
                    reject(new Error("Could not decode JSON."));
                }))
                .then((response) => {
                    if (response && response.success === false) {
                        if (response.data.includes("outdated")) {
                            reject(new Error(response.data));
                        } else {
                            reject(new Error("API call failed."));
                        }
                    } else {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async post(endpoint, content) {
        return new Promise((res, reject) => {
            let resource = `${Api.baseUrls[Setting.get("organization", true, window.sessionStorage)]}${endpoint}`

            let options = this.getOptions("post", content);

            Setting.set("meta.lastActivity", moment().toISOString());
            fetch(resource, options)
                .then((data) => {
                    if (data.status >= 200 && data.status < 300) {
                        return data;
                    } else if (
                        data.status === 401 &&
                        window.location.pathname !== "/"
                    ) {
                        Setting.clear("session", window.sessionStorage);
                        window.location.href = "/";
                        reject("Unauthorized");
                    } else {
                        Sentry.captureException(new Error(`POST ${resource} failed (${data.status})`));
                        return "error: status code: " + data.status;
                    }
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    return err;
                })

                .then((data) => {
                    if (
                        data &&
                        typeof data === "string" &&
                        data.indexOf("error: status code:") > -1
                    ) {
                        Sentry.captureException(new Error(`POST ${resource} failed`));
                        reject("There was an error with the post data; " + data);
                    } else {
                        if (data.statusText === "OK" || data.ok === true) {
                            res(data.json());
                            return;
                        }
                        try{
                            res(data.json());
                        }
                        catch (err) {
                            Sentry.captureException(err);
                            res(data);
                        }
                    }
                });
        });
    }

    async put(endpoint, content) {
        return new Promise((res, reject) => {
            let resource = `${Api.baseUrls[Setting.get("organization", true, window.sessionStorage)]}${endpoint}`
            let options = this.getOptions("put", content);

            Setting.set("meta.lastActivity", moment().toISOString());
            fetch(resource, options)
                .then((data) => {
                    if (data.status >= 200 && data.status < 300) {
                        return data;
                    } else if (
                        data.status === 401 &&
                        window.location.pathname !== "/"
                    ) {
                        Setting.clear("session", window.sessionStorage);
                        window.location.href = "/";
                        reject("Unauthorized");
                    } else {
                        Sentry.captureException(new Error(`PUT ${resource} failed (${data.status})`));
                        throw new Error("Fetch of " + resource + " failed");
                    }
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    return "error";
                })

                .then((data) => {
                    if (data) {
                        if (data === "error") {
                            Sentry.captureException(new Error(`PUT ${resource} failed`));
                            reject(data);
                        } else {
                            res(data.json());
                        }
                    } else {
                        res();
                    }
                });
        });
    }

    async delete(endpoint) {
        return new Promise((res, reject) => {
            let resource = `${Api.baseUrls[Setting.get("organization", true, window.sessionStorage)]}${endpoint}`
            let options = this.getOptions("delete");

            Setting.set("meta.lastActivity", moment().toISOString());
            fetch(resource, options)
                .then((data) => {
                    if (data.status >= 200 && data.status < 300) {
                        return data;
                    } else {
                        Sentry.captureException(new Error(`DELETE ${resource} failed (${data.status})`));
                        throw new Error("Fetch of " + resource + " failed");
                    }
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    return "error";
                })

                .then((data) => {
                    if (data) {
                        if (data === "error") {
                            Sentry.captureException(new Error(`DELETE ${resource} failed`));
                            reject(data);
                        } else {
                            try {
                                res(data.json());
                            }
                            catch (err) {
                                Sentry.captureException(err);
                                res(data);
                            }
                        }
                    } else {
                        res();
                    }
                });
        });
    }

    async downloadPost(endpoint) {
        return new Promise((res, reject) => {
            let resource = `${Api.baseUrls[Setting.get("organization", true, window.sessionStorage)]}${endpoint}`
            let options = this.buildDownloadConfig("post");

            Setting.set("meta.lastActivity", moment().toISOString());
            fetch(resource, options)
                .then((data) => {
                    if (data.status >= 200 && data.status < 300) {
                        return data;
                    } else {
                        Sentry.captureException(new Error(`POST ${resource} failed (${data.status})`));
                        return "error: status code: " + data.status;
                    }
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    return err;
                })

                .then((data) => {
                    if (
                        data &&
                        typeof data === "string" &&
                        data.indexOf("error: status code:") > -1
                    ) {
                        Sentry.captureException(new Error(`POST ${resource} failed`));
                        reject("There was an error with the post data; " + data);
                    } else {
                        res(data.json());
                    }
                });
        });
    }

    async getFile(endpoint, params) {
        return new Promise((res, reject) => {
            let resource = `${Api.baseUrls[Setting.get("organization", true, window.sessionStorage)]}${endpoint}`

            if (params) {
                resource = `${resource}?${new window.URLSearchParams(params).toString()}`;
            }

            let options = this.getOptions('get');

            Setting.set("meta.lastActivity", moment().toISOString());
            fetch(resource, options)
                .then((data) => {
                    if (data.status >= 200 && data.status < 300) {
                        return data;
                    } else {
                        Sentry.captureException(new Error(`GET ${resource} failed (${data.status})`));
                        reject(data);
                    }
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    return "error";
                })
                .then((data) => {
                    if (data) {
                        if (data === "error") {
                            Sentry.captureException(new Error(`GET ${resource} failed`));
                            reject(data);
                        } else {
                            res(data.blob());
                        }
                    } else {
                        res();
                    }
                });
        });
    }

    async getFileFromPost(endpoint, content) {
        return new Promise((res, reject) => {
            let resource = `${Api.baseUrls[Setting.get("organization", true, window.sessionStorage)]}${endpoint}`
            let options = this.getOptions('post', content);

            Setting.set("meta.lastActivity", moment().toISOString());
            fetch(resource, options)
                .then((data) => {
                    if (data.status >= 200 && data.status < 300) {
                        return data;
                    } else {
                        Sentry.captureException(new Error(`POST ${resource} failed (${data.status})`));
                        reject(data);
                    }
                })
                .catch((err) => {
                    Sentry.captureException(err);
                    return "error";
                })
                .then((data) => {
                    if (data) {
                        if (data === "error") {
                            Sentry.captureException(new Error(`POST ${resource} failed`));
                            reject(data);
                        } else {
                            res(data.blob());
                        }
                    } else {
                        res();
                    }
                });
        });
    }
}
const api = new Api();

export default api;
