import React from "react";
import Layer from "../Layer";
import { Redirect, BrowserRouter as Router, Route } from "react-router-dom";
import Security from "../Utility/Security";
import MUIBackdrop from '@mui/material/Backdrop';
import MUIPaper from "@mui/material/Paper";
import Progress from "../Component/Progress";
import moment from "moment-timezone";
import Setting from "../Utility/Setting";
import * as Sentry from "@sentry/react";
import api from "../Seating/Security/api";

export default class Load extends Layer {
    constructor(props) {
        super(props);
        this.state.loadingCount = 0;
        this.state.loaded = false;
    }

    afterComponentDidMount() {
        const promises = [
            this.getUsers(), // This has to be first
            this.getTable("/allTerms", ["termsIndexed"]),
            this.getTable("/allTeamQueues", ["teamQueuesIndexed"]),
            this.getTable("/allOrderActivityTypes", ["orderActivityTypesIndexed"]),
            this.getTable("/reverseQualityReason", ["reverseQualityReasons", "reverseQualityReasonsIndexed"]),
            this.getTable("/allTeams", ["teamsIndexed"]),
            this.getTable("/allTeamUsers", ["teamUsersIndexed"]),
            this.getTable("/contactTypes", ["contactTypes", "contactTypesIndexed"]),
            this.getTable("/insurance", ["insuranceTypes", "insuranceTypesIndexed"]),
            this.getTable("/products", ["productTypes", "productTypesIndexed"]),
            this.getTable("/productSubTypes", ["productSubTypes", "productSubTypesIndexed"]),
            this.getTable("/regions", ["allRegions", "regionsIndexed"]),
            this.getTable("/reasons", ["reasons", "orderStatusReasonsIndexed"]),
            this.getTable("/creditCardTypes", ["cardTypes", "creditCardTypesIndexed"]),
            this.getTable("/activeExpenseTypes", ["expenseTypes", "expenseTypesIndexed"]),
            this.getTable("/documentTypes", ["documentTypes", "documentTypesIndexed"]),
            this.getTable("/accountTypes", ["accountTypes", "accountTypesIndexed"]),
            this.getTable("/campaigns", ["marketingCampaigns", "marketingCampaignsIndexed"]),
            this.getTable("/campaigns/category", ["marketingCampaignCategories", "marketingCampaignCategoriesIndexed"]),
            this.getTable("/issueCategories", ["complaintCategories", "issueCategoriesIndexed"]),
            this.getTable("/issueReasons", ["complaintReasons", "issueReasonsIndexed"]),
            this.getTable("/vendors", ["allVendors", "vendorsIndexed"]),
            this.getTable("/allColors", ["colorsIndexed"]),
            this.getTable("/users/salesPCRLinks", ["salesPcrLinksIndexed"]),
            this.getTable("/userProfileLocations", ["userprofileLocationsIndexed"]),
            this.getTable("/baseUnits", ["allBaseUnits", "baseUnitsIndexed"]),
            this.getTable("/baseUnitTypes", ["allBaseUnitTypes", "baseUnitTypesIndexed"]),
            this.getTable("/productMatrixEntry", ["productOverrides"]),
            this.getTable("/patientCommunicationTriggerDefs", ["patientCommunicationTriggerDefs"]),
            this.getTable("/hcpcsCode", ["hcpcsCodesIndexed"]),
            this.getTable("/DistributionReason", ["distributionReasonsIndexed"]),
            this.getTable("/userProductionType", ["userProductionTypesIndexed"]),
            this.getQueues(),
            this.getQueueRoutes(),
            this.getDepartments(),
            this.getInsuranceSubTypes(),
            this.getLocations(),
            this.getOrderStatuses(),
            this.getIssueStatuses(),
            this.getServiceReasons(),
            this.getStateSalesTax(),
            this.getReverseQualityCategories(),
            this.getMyAccounts(),
        ];

        this.setState({ loadingCount: promises.length });
        this.setState({ loadingDone: 0 });

        this.context.setCurrentUser(Setting.get("userprofile"));
        this.context.setCompanyName(Setting.get("organization"));

        // Sentry setup
        const userprofile = Setting.get("userprofile");
        Sentry.setUser({
            id: userprofile.id,
            username: userprofile.username.toLowerCase(),
            email: userprofile.email.toLowerCase()
        });
        Sentry.setTag("organization", Setting.get("organization"));

        // Send API calls
        promises.forEach((promise) => {
            promise.then(() => {
                this.setState({ loadingDone: this.state.loadingDone + 1 });
            });
        });

        Promise.all(promises).then(data => {
            // Update the context with the fetched data.
            data.forEach(datum => {
                /**
                 * This is not ideal. Before Okta, this big load API call had a
                 * catch at the end of it that was throwing away all exceptions
                 * and letting the code proceed. I removed that when updating to
                 * Okta, but that caused problems when any of these load API
                 * calls failed. It would just get to 100% loaded and get stuck
                 * at the load layer. This check still allows it to proceed,
                 * just without the data. This could still break the application
                 * if the page you're trying to load needs the data that failed.
                 * That's not great, but the bigger issue right now is that
                 * these calls fail more often than they should to begin with.
                 * Working on identifying the problem calls and then we can work
                 * on refactoring this to not ignore errors.
                 */
                if (datum) {
                    Object.entries(datum).forEach(([key, value]) => {
                        this.context.setContextItem(key, value);
                    });
                } else {
                    console.warn("Failed to load all data. Check network logs to see what's missing.");
                }
            });

            /**
             * The users one sets a bunch of other helper state objects. This is
             * stupid, but for now make sure that one is first in the
             * Promise.all() call so the below line of code works.
            */
            this.sortUsers(Object.values(data[0].usersIndexed));

            // Set the security context
            Security.setContext(this.context);

            // Mark that Sales Pilot has finished loading.
            this.context.setContextItem("loaded", true);
            window.salesPilot.meta.loadedLegacy = true;
            this.setState({ loaded: true });
        });
    }

    renderContent() {
        if (this.state.loaded === true) {
            const to = this?.props?.location?.state?.from ? this.props.location.state.from : "/dashboard"
            return <Redirect to={to} />
        }

        return (
            <MUIBackdrop open={true}>
                <MUIPaper sx={{ padding: 4, width: 400 }}>
                    <Progress
                        variant="determinate"
                        value={this.state.loadingDone / this.state.loadingCount * 100}
                        message="Fetching data..."
                    />
                </MUIPaper>
            </MUIBackdrop>
        );
    }

    getBackgroundColor() {
        return "transparent";
    }

    /**
     * Generically fetch a table from the database. Works for most newer tables
     * that don't require any custom logic or parsing. Also supports legacy
     * tables that have both an indexed and a non-indexed version in
     * context/storage.
     *
     * @param {string} endpoint The endpoint to get from.
     * @param {string} keys The sessionStorage keys.
     */
    async getTable(endpoint, keys) {
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            return api.send("GET", endpoint)
                .then((data) => {
                    keys.forEach((key) => {
                        if (key.includes("Indexed")) {
                            Setting.set(key, Load.indexById(data), window.sessionStorage);
                        } else {
                            Setting.set(key, data, window.sessionStorage);
                        }
                    });
                    return this.getObject(keys);
                })
                .catch((error) => {
                    // Ignore (for now).
                });
        }
    }

    /**
     * Load a table and save it into context. If it's already in the context, it
     * does nothing. Good for loading larger tables and making sure it only
     * happens once per tab.
     *
     * @param {string} table The table to load.
     * @param {object} context The React context object.
     */
    static getTableLazy(table, context) {
        let endpoint;
        let key;

        switch (table) {
            case "servicePartCatalog":
                endpoint = "/allServiceParts";
                key = "servicePartCatalogsIndexed";
                break;
            default:
                return Promise.resolve();
        }

        if (Object.keys(context[key]).length === 0) {
            return api.send("GET", endpoint)
                .then((data) => {
                    context.setContextItem(key, Load.indexById(data));
                })
                .catch((error) => {
                    // Ignore (for now).
                });
        }

        return Promise.resolve(); // Context already populated
    }

    /**
     * Checks to see if this set of keys exists in settings.
     *
     * @param {array} keys
     *
     * @returns {boolean}
     */
    keysExist(keys) {
        for (const key of keys) {
            if (!Setting.get(key, false, window.sessionStorage)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Generates an object with the provided keys and values from settings.
     *
     * @param {array} keys
     *
     * @returns {object}
     */
    getObject(keys) {
        const object = {};
        for (const key of keys) {
            object[key] = Setting.get(key, false, window.sessionStorage);
        }
        return object;
    }

    /**
     * Index an array of rows by ID.
     *
     * @param {array} rows An array of rows to index. Each row *must* have an
     * "id" property.
     * @returns {object} An object containing the indexed rows.
     */
    static indexById(rows) {
        const indexedById = {};
        let i = 0; // For the few tables that don't have ID (ex: userprofileLocations)
        rows.forEach((row) => {
            indexedById[row.id ? row.id : i++] = row;
        });
        return indexedById;
    }

    /**
     * Get all queues. Also properly parses the JSON columns since the API is
     * not yet doing that.
     */
    async getQueues() {
        const keys = ["queuesIndexed"];
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            return api.send("GET", "/allQueues")
                .then((data) => {
                    data = data.map((queue) => {
                        let parsedQueue = queue;
                        if (parsedQueue.filter) {
                            parsedQueue = { ...parsedQueue, ...{ filter: JSON.parse(parsedQueue.filter) } };
                        }
                        if (parsedQueue.view) {
                            parsedQueue = { ...parsedQueue, ...{ view: JSON.parse(parsedQueue.view) } };
                        }
                        return parsedQueue;
                    });

                    Setting.set("queuesIndexed", Load.indexById(data), window.sessionStorage);
                    return this.getObject(keys);
                })
                .catch((error) => {
                    // Ignore (for now).
                });
        }
    }

    /**
     * Get all queue routes. Also properly parses the JSON columns since the API
     * is not yet doing that.
     */
    async getQueueRoutes() {
        const keys = ["queueRoutesIndexed"];
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            return api.send("GET", "/allQueueRoutes")
                .then((data) => {
                    data = data.map((queue) => {
                        let parsedQueueRoute = queue;
                        if (parsedQueueRoute.jsonSubTypes) {
                            parsedQueueRoute = { ...parsedQueueRoute, ...{ jsonSubTypes: JSON.parse(parsedQueueRoute.jsonSubTypes) } };
                        }
                        return parsedQueueRoute;
                    });

                    Setting.set("queueRoutesIndexed", Load.indexById(data), window.sessionStorage);
                    return this.getObject(keys);
                })
                .catch((error) => {
                    // Ignore (for now).
                });
        }
    }

    /**
     * Get all queue routes. Also properly parses the JSON columns since the API
     * is not yet doing that.
     */
    async getDepartments() {
        const keys = ["departmentsIndexed"];
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            return api.send("GET", "/allDepartments")
                .then((data) => {
                    data = data.map((queue) => {
                        let parsedDepartment = queue;
                        if (parsedDepartment.jsonPrefilters) {
                            parsedDepartment = { ...parsedDepartment, ...{ jsonPrefilters: JSON.parse(parsedDepartment.jsonPrefilters) } };
                        }
                        return parsedDepartment;
                    });

                    Setting.set("departmentsIndexed", Load.indexById(data), window.sessionStorage);
                    return this.getObject(keys);
                })
                .catch((error) => {
                    // Ignore (for now).
                });
        }
    }

    /**
     * Get all insurance sub types. Does all the normal stuff, but does some
     * sorting as well so need the custom function for now.
     */
    async getInsuranceSubTypes() {
        const keys = ["insuranceSubTypes", "insuranceSubTypesIndexed"];
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            return api.send("GET", "/insuranceSubTypes")
                .then((data) => {
                    const dataSorted = data.sort((a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    });
                    Setting.set("insuranceSubTypes", dataSorted, window.sessionStorage);
                    Setting.set("insuranceSubTypesIndexed", Load.indexById(data), window.sessionStorage);
                    return this.getObject(keys);
                })
                .catch((error) => {
                    // Ignore (for now).
                });
        }
    }

    /**
     * Get all locations. Does some custom sorting and filtering so need the
     * custom function.
     */
    async getLocations() {
        const keys = ["allLocations", "locationsIndexed", "internalLocations"];
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            return api.send("GET", "/locations")
                .then((data) => {
                    const dataSorted = data.sort((a, b) => {
                        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                    });
                    const internalLocations = dataSorted.filter(x => x.type !== null && x.type === 0);

                    Setting.set("allLocations", dataSorted, window.sessionStorage);
                    Setting.set("locationsIndexed", Load.indexById(data), window.sessionStorage);
                    Setting.set("internalLocations", internalLocations, window.sessionStorage);
                    return this.getObject(keys);
                })
                .catch((error) => {
                    // Ignore (for now).
                });
        }
    }

    /**
     * Get all order statuses. This is just a manual list for now.
     */
    async getOrderStatuses() {
        const keys = ["orderStatusesIndexed"];
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            const data = {
                "0": {
                    id: 0,
                    name: "New",
                    colorId: 76
                },
                "1": {
                    id: 1,
                    name: "In Process",
                    colorId: 202
                },
                "2": {
                    id: 2,
                    name: "Ready to Deliver",
                    colorId: 118
                },
                "3": {
                    id: 3,
                    name: "Setup",
                    colorId: 132
                },
                "4": {
                    id: 4,
                    name: "Cancelled",
                    colorId: 6
                },
            };
            Setting.set("orderStatusesIndexed", data, window.sessionStorage);
            return this.getObject(keys);
        }
    }

    /**
     * Get all issue statuses. This is just a manual list for now.
     */
    async getIssueStatuses() {
        const keys = ["issueStatusesIndexed"];
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            const data = {
                "0": {
                    id: 0,
                    name: "Open",
                },
                "1": {
                    id: 1,
                    name: "Closed",
                },
            };
            Setting.set("issueStatusesIndexed", data, window.sessionStorage);
            return this.getObject(keys);
        }
    }

    /**
     * Get all state sales tax. Does a bit of manual stuff.
     */
    async getStateSalesTax() {
        const keys = ["stateSalesTax"];
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            return api.send("GET", "/stateSalesTax")
                .then((data) => {
                    let dataModified = [];

                    data.forEach((stateSalesTax) => {
                        dataModified.push({
                            id: stateSalesTax.id,
                            name: stateSalesTax.name,
                            abbr: stateSalesTax.abbr,
                            salesTax: parseFloat(stateSalesTax.salesTax),
                        });
                    });
                    Setting.set("stateSalesTax", dataModified, window.sessionStorage);
                    return this.getObject(keys);
                })
                .catch((error) => {
                    // Ignore (for now).
                });
        }
    }

    /**
     * Get all reverse quality categories.
     */
    async getReverseQualityCategories() {
        const keys = ["reverseQualityCategories", "reverseQualityCategoriesRefs"];
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            return api.send("GET", "/reverseQualityCategory")
                .then((data) => {
                    let reverseQualityCategories = [];
                    let reverseQualityCategoriesRefs = [];

                    data.forEach((datum) => {
                        reverseQualityCategoriesRefs.push({
                            label: datum.name,
                            value: {
                                id: datum.id,
                                name: datum.name,
                            },
                        });

                        reverseQualityCategories.push({
                            id: datum.id,
                            name: datum.name,
                        });
                    });

                    Setting.set("reverseQualityCategories", reverseQualityCategories, window.sessionStorage);
                    Setting.set("reverseQualityCategoriesRefs", reverseQualityCategoriesRefs, window.sessionStorage);
                    return this.getObject(keys);
                })
                .catch((error) => {
                    // Ignore (for now).
                });
        }
    }

    /**
     * Get my accounts. Skips session storage as for many people this is too
     * large.
     */
    async getMyAccounts() {
        let userprofile = Setting.get("userprofile");
        return api.send("GET", `/users/${userprofile.id}/accounts`)
            .then((data) => {
                return ({ myAccounts: data });
            })
            .catch((error) => {
                // Ignore (for now).
            });
    }

    /**
     * Get users. Skips session storage as this is getting too large right now.
     */
    async getUsers() {
        return api.send("GET", `/allUsersAsync`)
            .then((data) => {
                return ({ usersIndexed: Load.indexById(data) });
            })
            .catch((error) => {
                // Ignore (for now).
            });
    }

    /**
     * Manually provide these since there's no API or table yet.
     * select count(*), service_reason from service_order_reason group by service_reason order by count(*) desc;
     */
    async getServiceReasons() {
        const keys = ["serviceReasonsIndexed"];
        if (this.keysExist(keys)) {
            return this.getObject(keys);
        } else {
            const data = {
                "1": {
                    id: 1,
                    name: "Tech Skilled Labor",
                    description: "Tech Skilled Labor",
                },
                "2": {
                    id: 2,
                    name: "Setup Issue",
                    description: "Setup Issue – patient was not setup correctly on initial delivery",
                },
                "3": {
                    id: 3,
                    name: "Quality Issue",
                    description: "Quality Issue – equipment is a lemon or has repeat issues on same parts",
                },
                "4": {
                    id: 4,
                    name: "Service Part Needed",
                    description: "Service Part Needed",
                },
                "5": {
                    id: 5,
                    name: "Created In Field",
                    description: "CreatedInField",
                },
                "6": {
                    id: 6,
                    name: "Equipment Fit",
                    description: "Equipment Fit – ATP recommendations were not correct initially",
                },
                "7": {
                    id: 7,
                    name: "Misdiagnosed",
                    description: "Misdiagnosed – last part recommendation was incorrect",
                },
                "8": {
                    id: 8,
                    name: "Equipment Abuse",
                    description: "Equipment Abuse – patient is using equipment incorrectly or abusing equipment",
                },
                "9": {
                    id: 9,
                    name: "Manufacturer Error",
                    description: "Manufacturer Error – vendor sent wrong parts or suggested wrong parts",
                },
                "10": {
                    id: 10,
                    name: "New Accessory After The Sale",
                    description: "New Accessory After The Sale",
                },
                "11": {
                    id: 11,
                    name: "30 Day Fit 4 U",
                    description: "30 Day Fit 4 U",
                },
                "12": {
                    id: 12,
                    name: "Field Service Diagnosis",
                    description: "Field Service Diagnosis",
                },
                "13": {
                    id: 13,
                    name: "Issue Resolved Over the Phone",
                    description: "Issue Resolved Over the Phone",
                },
                "14": {
                    id: 14,
                    name: "Patient Request - 5 Day",
                    description: "Patient Request - 5 Day",
                },
                "15": {
                    id: 15,
                    name: "Patient Request - Rental",
                    description: "Patient Request - Rental",
                },
                "16": {
                    id: 16,
                    name: "Patient Request - Loaner",
                    description: "Patient Request - Loaner",
                },
                "17": {
                    id: 17,
                    name: "Patient Request - Exchange",
                    description: "Patient Request - Exchange",
                },
                "18": {
                    id: 18,
                    name: "Active Rental - Insurance Change",
                    description: "Active Rental - Insurance Change",
                },
                "19": {
                    id: 19,
                    name: "Active Rental - SNF/Hospice",
                    description: "Active Rental - SNF/Hospice",
                },
                "20": {
                    id: 20,
                    name: "Active Rental - Insurance Auth Expired",
                    description: "Active Rental - Insurance Auth Expired",
                },
                "21": {
                    id: 21,
                    name: "Delivery Denial - Insurance Change",
                    description: "Delivery Denial - Insurance Change",
                },
                "22": {
                    id: 22,
                    name: "Delivery Denial - SNF/Hospice",
                    description: "Delivery Denial - SNF/Hospice",
                },
                "23": {
                    id: 23,
                    name: "ATP Visit",
                    description: "ATP Visit",
                },
            };
            Setting.set("serviceReasonsIndexed", data, window.sessionStorage);
            return this.getObject(keys);
        }
    }

    sortUsers(users) {
        let cu = this.context.currentUser;
        let atp = [],
            thera = [],
            active = [],
            liaisons = [],
            techs = [],
            third = [],
            pcr = [],
            reps = cu.role === "SALES" ? [cu] : [],
            ary = [];

        users.map(x => {
            if (x.isAtp === true) {
                atp.push(x);
            }
            if (x.active === true) {
                active.push(x);
            }
            if (x.deleted !== true) {
                switch (x.role) {
                    case "SALES_LIAISON":
                        liaisons.push(x);
                        break;
                    case "OFFICE_MANAGER":
                        techs.push(x);
                        pcr.push(x);
                        break;
                    case "AREA_MANAGER":
                    case "TECHNICIAN":
                        techs.push(x);
                        break;
                    case "PATIENT_CARE_REP":
                    case "OFFICE_SUPPORT":
                        pcr.push(x);
                        break;
                    case "SALES_MANAGER":
                    /**
                     * 2024-12-12. Adding MARKETING_MANAGER to the list of Sales
                     * Reps. These users used to be SALES_MANAGER, but I moved
                     * them to a new MARKETING_MANAGER role to better separate
                     * these users.
                     *
                     * From Matt: Just to clarify, our process is to have the
                     * lead coordinators capture new leads by creating an order
                     * and assigning to Katelyn as the sales rep. Katelyn then
                     * goes in and re-assigns any orders in her name to the
                     * local rep responsible for taking care of those patients
                     * based on territory lines.
                     */
                    case "MARKETING_MANAGER":
                        pcr.push(x);
                        if (cu.role !== "SALES" && x.deleted !== true) {
                            reps.push(x);
                        }
                        break;
                    case "SALES":
                    case "ADMIN":
                        if (cu.role !== "SALES" && x.deleted !== true) {
                            reps.push(x);
                        }
                        break;
                    default:
                        break;
                }
            }
            ary.push({
                label: x.firstname + " " + x.lastname,
                value: { id: x.id, name: x.username },
            });

            return x;
        });

        this.context.setContextItem("allUsers", users);
        this.context.setContextItem("allAtp", atp);
        this.context.setContextItem("userRefs", ary);
        this.context.setContextItem("allActiveUsers", active);
        this.context.setContextItem("allLiaisons", liaisons);
        this.context.setContextItem("techs", techs);
        this.context.setContextItem("salesReps", reps);
        this.context.setContextItem("therapists", thera);
        this.context.setContextItem("thirdPartyUsers", third);
        this.context.setContextItem("thirdPartyUsersLoaded", true);
        this.context.setContextItem("allPCR", pcr);

        this.populateLiaisonKeys(liaisons);
    }

    populateLiaisonKeys(allLiaisons) {
        let aryDates = [],
            liaisonsKeys = [],
            startDate = new Date();

        let currentTzo = new Date().getTimezoneOffset(),
            id = moment.tz(new Date(), "America/Indiana/Indianapolis"),
            idTzo = id._offset,
            variation = 0;

        //subtract indiana's timezone offset...BC (it is negative 300, so add negative)
        currentTzo += idTzo;

        //get the offset in hours...BC
        if (currentTzo !== 0) {
            variation = currentTzo / 60;
        }

        let start = 9,
            end = 16;

        //they will have a lot less choices to schedule a meeting based on their timezone...BC
        if (variation !== 0) {
            start = 9 - variation;
            end = 16 - variation;
        }

        for (let i = 0; i <= 5; i++) {
            let currentDate = new Date();
            currentDate.setDate(startDate.getDate() + i);

            if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
                continue;
            }

            let mString =
                (currentDate.getMonth() + 1).toString() +
                "/" +
                currentDate.getDate().toString() +
                "/" +
                currentDate.getFullYear().toString() +
                " | ";

            //this should generate a keystring of DDMMYYYYTTTT
            for (let i = start; i <= end; i++) {
                aryDates.push(mString + i + ":00:00--");
            }
        }

        aryDates.forEach((d) => {
            allLiaisons.forEach((l) => {
                liaisonsKeys.push(d + l.firstname + " " + l.lastname);
            });
        });

        this.context.setContextItem("fullLiaisonKeys", liaisonsKeys);
        this.context.setContextItem("timezoneVariation", variation);
    }

}