import React from "react";
import Dialog from "../Dialog";
import MUITypography from "@mui/material/Typography";
import MUIAccordion from '@mui/material/Accordion';
import MUIAccordionSummary from '@mui/material/AccordionSummary';
import MUIAccordionDetails from '@mui/material/AccordionDetails';
import MUIExpandMoreIcon from '@mui/icons-material/ExpandMore';
export default class DialogUpdateNotes extends Dialog {
    /**
    * Render header title of dialog
    */
    renderHeader() {
        return (
            <MUITypography variant="h6" color="textPrimary">
                Update Notes
            </MUITypography>
        );
    }

    /**
     * Render main content text of dialog
     */
    renderContent() {
        return (
            <>
                <MUIAccordion defaultExpanded>
                    <MUIAccordionSummary expandIcon={<MUIExpandMoreIcon />}>
                        Sales Pilot 5.6
                    </MUIAccordionSummary>
                    <MUIAccordionDetails>
                        <div>
                            <strong>5.6.12 • 4/9/2025</strong>
                            <ul>
                                <li>Added support for new Part Return queue</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.11 • 4/8/2025</strong>
                            <ul>
                                <li>Fixed order CSV export broken from new Started At column</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.10 • 4/7/2025</strong>
                            <ul>
                                <li>Added ability to manually set Brightree ID for orders and tickets</li>
                                <li>Updated Invoice/Denial/AR features to latest</li>
                                <li>Added Started At to Orders search page</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.9 • 4/4/2025</strong>
                            <ul>
                                <li>Fixed tooltip on queue name</li>
                                <li>Added manager user to team in queue sidebar</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.8 • 3/29/2025</strong>
                            <ul>
                                <li>Updated Invoice/Denial/AR features to latest</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.7 • 3/28/2025</strong>
                            <ul>
                                <li>Fixed rescheduling Sales Liaison meetings allowing double-booking</li>
                                <li>Fixed saving filters on Orders page throwing an error</li>
                                <li>Added indicator to closed feedback</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.6 • 3/21/2025</strong>
                            <ul>
                                <li>Added manager to patient issue assignee dropdown</li>
                                <li>Alphabetized and removed inactive users from patient issue assignee dropdown</li>
                                <li>Added address to location admin page</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.5 • 3/12/2025</strong>
                            <ul>
                                <li>Opened up access to Metric Report to Executive Management</li>
                                <li>Updated Invoice Queues & AR Dashboard</li>
                                <li>Improved performance of Sales Dashboard</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.4 • 3/12/2025</strong>
                            <ul>
                                <li>Fixed certain patients not opening</li>
                                <li>Fixed issue when adding multiple of the same part to a service ticket</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.3 • 3/9/2025</strong>
                            <ul>
                                <li>Fixed orders allowed to schedule past deliver by date</li>
                                <li>Fixed Metric report not being inclusive of end date</li>
                                <li>Removed weekends and holidays from metric report</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.2 • 3/7/2025</strong>
                            <ul>
                                <li>Fixed orders sometimes not loading</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.1 • 3/7/2025</strong>
                            <ul>
                                <li>Fixed orders sometimes not loading</li>
                                <li>Made NPI not required on location</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.6.0 • 3/7/2025</strong>
                            <ul>
                                <li>Added and amended required fields when approving an order</li>
                                <li>Upgraded look & feel of approvals tab for orders</li>
                                <li>Reduced users in customer feedback assignee dropdown list</li>
                                <li>Added ATP to service, sales, and complaint reports</li>
                                <li>Added Vendor to sales report</li>
                                <li>Fixed verification route back to sender sometimes not working</li>
                            </ul>
                        </div>
                    </MUIAccordionDetails>
                </MUIAccordion>
                <MUIAccordion>
                    <MUIAccordionSummary expandIcon={<MUIExpandMoreIcon />}>
                        Sales Pilot 5.5
                    </MUIAccordionSummary>
                    <MUIAccordionDetails>
                        <div>
                            <strong>5.5.13 • 2/31/2025</strong>
                            <ul>
                                <li>Fixed Reverse Quality log report when running with no users selected</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.12 • 2/28/2025</strong>
                            <ul>
                                <li>Possibly fixed issue when opening orders from sales queues</li>
                                <li>Fixed billing issue tickets opening in wrong view from deliveries page</li>
                                <li>Fixed minor issue when reloading Month at a Glance report</li>
                                <li>Removed old Internal Request queue page</li>
                                <li>Added deceased and marketing contact fields to patient info screen</li>
                                <li>Added informational notice to billing issue redeliveries equipment section</li>
                                <li>Added invoice queue type and AR Report</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.11 • 2/14/2025</strong>
                            <ul>
                                <li>Added marketing prefilter to dashboard</li>
                                <li>Migrated Reverse Quality Logs report to new look & feel</li>
                                <li>Fixed insurance type edit page not loading</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.10 • 2/10/2025</strong>
                            <ul>
                                <li>Added auto-retry to failed load calls</li>
                                <li>Fixed scroll issue with Brightree ID dialog</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.9 • 2/7/2025</strong>
                            <ul>
                                <li>Fixed scroll issues with timeline and certain dialogs</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.8 • 2/6/2025</strong>
                            <ul>
                                <li>Added category and tech visible columns to service parts catalog</li>
                                <li>Added new service part category admin page</li>
                                <li>Updated patient information page to reload automatically after Brightree sync</li>
                                <li>Improved behind the scenes logic and error reporting for loading screen</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.7 • 1/30/2025</strong>
                            <ul>
                                <li>Added new metric report</li>
                                <li>Fixed tickets not loading if they contain deleted parts</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.6 • 1/27/2025</strong>
                            <ul>
                                <li>Added "Route to Verification" button to verification dialog</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.5 • 1/24/2025</strong>
                            <ul>
                                <li>Fixed complaints not always closing correctly</li>
                                <li>Added "Create Brightree Order" to patient information page</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.4 • 1/23/2025</strong>
                            <ul>
                                <li>Add followup date note option to Unable To Bill - Followup</li>
                                <li>Fixed intermittent login issue</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.3 • 1/23/2025</strong>
                            <ul>
                                <li>Changed queue search box to red if there are no results</li>
                                <li>Removed verifications that are not attached to the current ticket from the list</li>
                                <li>Fixed insurance verification dialog sometimes not clearing the last opened verification</li>
                                <li>Fixed Billing Issue orders not always opening the correct ticket/order page</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.2 • 1/20/2025</strong>
                            <ul>
                                <li>Updated verification note dialog on new orders to require the "Insurance Verified" flag</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.1 • 1/15/2025</strong>
                            <ul>
                                <li>Allowed canceled orders to be sent back to Intake</li>
                                <li>Added message when queue fails to load</li>
                                <li>Migrated account types to the new look and feel</li>
                                <li>Migrated contact types to the new look and feel</li>
                                <li>Migrated document types to the new look and feel</li>
                                <li>Improved performance of the Customer Feedback queues</li>
                                <li>Fixed bug where sometimes note could add to wrong ticket after creating a verification note</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.5.0 • 1/12/2025</strong>
                            <ul>
                                <li>Updated login to use Single sign-on with Okta</li>
                                <li>Allowed copy/pasting URLs in new tab without losing session</li>
                                <li>Refactored first load experience to be more reliable</li>
                                <li>Updated background image</li>
                            </ul>
                        </div>
                    </MUIAccordionDetails>
                </MUIAccordion>
                <MUIAccordion>
                    <MUIAccordionSummary expandIcon={<MUIExpandMoreIcon />}>
                        Sales Pilot 5.4
                    </MUIAccordionSummary>
                    <MUIAccordionDetails>
                        <div>
                            <strong>5.5.0 • 1/12/2025</strong>
                            <ul>
                                <li>Updated login to use Single sign-on with Okta</li>
                            </ul>
                        </div>
                    </MUIAccordionDetails>
                </MUIAccordion>
                <MUIAccordion>
                    <MUIAccordionSummary expandIcon={<MUIExpandMoreIcon />}>
                        Sales Pilot 5.4
                    </MUIAccordionSummary>
                    <MUIAccordionDetails>
                        <div>
                            <strong>5.4.5 • 1/10/2025</strong>
                            <ul>
                                <li>Migrated Insurance Type page to new look & feel</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.4.4 • 1/8/2025</strong>
                            <ul>
                                <li>Disallowed changing new orders to Billing Issue/Internal Request</li>
                                <li>Added ability to inactiviate marketing campaigns</li>
                                <li>Fixed patient collections import not working if file too large</li>
                                <li>Fixed orders failing to open if pVerify returns invalid results</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.4.3 • 1/1/2025</strong>
                            <ul>
                                <li>Fixed pVerify errors sometimes breaking Sales Pilot</li>
                                <li>Fixed orders sometimes ordering due to phone number format</li>
                                <li>Fixed Preauth number not always showing up on approval note</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.4.2 • 12/31/2024</strong>
                            <ul>
                                <li>Fixed dialogs sometimes appearing under header bar</li>
                                <li>Fixed update button sometimes not working on contact info</li>
                                <li>Fixed Followup route not setting initial followup date</li>
                                <li>Fixed orders sometimes saving with no reason</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.4.1 • 12/28/2024</strong>
                            <ul>
                                <li>Fixed old Internal Request orders opening ticket page</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.4.0 • 12/27/2024</strong>
                            <ul>
                                <li>Created new Billing Issue ticket type to replace Internal Request</li>
                            </ul>
                        </div>
                    </MUIAccordionDetails>
                </MUIAccordion>
                <MUIAccordion>
                    <MUIAccordionSummary expandIcon={<MUIExpandMoreIcon />}>
                        Sales Pilot 5.3
                    </MUIAccordionSummary>
                    <MUIAccordionDetails>
                        <div>
                            <strong>5.3.10 • 12/24/2024</strong>
                            <ul>
                                <li>Unrestricted routing for Internal Request orders</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.3.9 • 12/21/2024</strong>
                            <ul>
                                <li>Unrestricted routing for new orders slightly</li>
                                <li>Fixed order sidebar not saving status on save</li>
                                <li>Fixed orders sometimes failing to generate approval note</li>
                                <li>Fixed pickup tickets not requiring equipment to be selected</li>
                                <li>Fixed delivery notes sometimes not saving</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.3.8 • 12/20/2024</strong>
                            <ul>
                                <li>Fixed Sales Bonus Report export corrupting if there are commas in the insurance name</li>
                                <li>Added Birdeye External ID to locations</li>
                                <li>Added new Patient Collections import & alert</li>
                                <li>Restricted routing for new orders</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.3.7 • 12/13/2024</strong>
                            <ul>
                                <li>Allowed Marketing Manager role to cancel orders</li>
                                <li>Fixed Brightree patient links not showing acknowledgements</li>
                                <li>Updated queues to save search query</li>
                                <li>Fixed department/team permission check not including managers/directors/executives</li>
                                <li>Fixed editing ticket information not reopening ticket after saving</li>
                                <li>Fixed points rounding to one decimal place in queues</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.3.6 • 12/5/2024</strong>
                            <ul>
                                <li>Fixed Same/Sim results not always showing all codes</li>
                                <li>Fixed Marketing Manager users not showing up in Sales User lists</li>
                                <li>Fixed Insurance admin page displaying the wrong Payor ID</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.3.5 • 11/28/2024</strong>
                            <ul>
                                <li>Updated pickup ticket service location to use parent ticket service location by default</li>
                                <li>Updated user tiles to show role and team</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.3.4 • 11/26/2024</strong>
                            <ul>
                                <li>At least one Policy # or MBI is now required to submit verification</li>
                                <li>Stopped sending verifications when Policy # or MBI is not present on secondary</li>
                                <li>Updated MBI requirements to follow Medicare standard</li>
                                <li>Fixed minor error with Reverse Quality tasks</li>
                                <li>Fixed minor errors for Patient Issue queues</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.3.3 • 11/23/2024</strong>
                            <ul>
                                <li>Added Brightree ID to patient screens</li>
                                <li>Fixed Sales queue sometimes labeling timeline with the wrong user</li>
                                <li>Fixed issue where some users could not load dashboard</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.3.2 • 11/22/2024</strong>
                            <ul>
                                <li>Fixed issue where some users unable to open patients</li>
                                <li>Migrated Order Status Reason admin page to new look & feel</li>
                                <li>Enabled Operations Prefilters on Dashboard</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.3.1 • 11/20/2024</strong>
                            <ul>
                                <li>Allowed Review to run eligibility on new orders</li>
                                <li>Added Brightree IDs and links to orders & tickets</li>
                                <li>Updated Brightree sync to both create and update patients</li>
                                <li>Updated Patient Collections feedback/issues to only be managed by Revenue Cycle team</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.3.0 • 11/15/2024</strong>
                            <ul>
                                <li>Added new app bar</li>
                                <li>Added inactivty logout notification</li>
                                <li>Updated insurance admin page to new look & feel</li>
                                <li>Added tooltips to routing options</li>
                            </ul>
                        </div>
                    </MUIAccordionDetails>
                </MUIAccordion>
                <MUIAccordion>
                    <MUIAccordionSummary expandIcon={<MUIExpandMoreIcon />}>
                        Sales Pilot 5.2
                    </MUIAccordionSummary>
                    <MUIAccordionDetails>
                        <div>
                            <strong>5.2.9 • 11/12/2024</strong>
                            <ul>
                                <li>Updated Sales Queues to use new Insurance Verified flag</li>
                                <li>Fixed bug allowing double-click resubmission when adding parts</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.2.8 • 11/8/2024</strong>
                            <ul>
                                <li>Added new "Insurance Verified" field requirement to verification notes</li>
                                <li>Migrated locations admin page to new look & feel</li>
                                <li>Added ATP to select sales queues</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.2.7 • 11/6/2024</strong>
                            <ul>
                                <li>Fixed minor issue with prefilters</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.2.6 • 11/5/2024</strong>
                            <ul>
                                <li>Fixed loading issue for Field Operations queues</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.2.5 • 11/4/2024</strong>
                            <ul>
                                <li>Fixed minor issue with new patient alerts</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.2.4 • 11/1/2024</strong>
                            <ul>
                                <li>Fixed inactive users showing in user search dialog</li>
                                <li>Fixed reopening a ticket sometimes not working</li>
                                <li>Updated team dictionary to not require a manager</li>
                                <li>Added informational tooltip to queue names</li>
                                <li>Added patient alerts and patient issue type of collections</li>
                                <li>Fixed Preauth Followup - Appeals notes not allowing setting the date</li>
                                <li>Fixed saving patients sometimes showing phone number warning</li>
                                <li>Fixed typing date on intake questionnaire not working</li>
                                <li>Fixed "Clear Open By Me" not working on Service - Preauth Followup</li>
                                <li>Fixed issue where sometimes orders could have an invalid reason when canceled</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.2.3 • 10/23/2024</strong>
                            <ul>
                                <li>Fixed 30 Day Volume not showing when queue has prefilters</li>
                                <li>Fixed Issue Categories not searchable in table</li>
                                <li>Added button to view existing patient intake questionnaires</li>
                                <li>Allowed adding parts to orders even with no base unit present on the parent order</li>
                                <li>Added user notification when overriding route from Needs Billed to Non Billable</li>
                                <li>Fixed new orders being set to Service - Obtain Docs instead of Intake</li>
                                <li>Fixed verification note sometimes not adding</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.2.2 • 10/18/2024</strong>
                            <ul>
                                <li>Fixed issue for some users loading dashboard</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.2.1 • 10/18/2024</strong>
                            <ul>
                                <li>Fixed issue with filtering on orders screen</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.2.0 • 10/18/2024</strong>
                            <ul>
                                <li>Added prefilter support to queues and queue dashboard</li>
                                <li>Added custom sales queue to better support process</li>
                                <li>Added automatic routing to Non-billable for Tech Skilled Labor and 30-Day Fit4U with no products attached.</li>
                                <li>Prevented updating cancelled service tickets</li>
                                <li>Added organization name to window title</li>
                                <li>Decreased inactivity timeout from 2 hours to 1 hour</li>
                                <li>Changed date format on production report</li>
                                <li>Updated Home Assessment to attach PDF to order when completing</li>
                                <li>Updated look and feel of new patient create screen</li>
                                <li>Disallowed Warehouse Tech and Office Support roles from canceling orders</li>
                            </ul>
                        </div>
                    </MUIAccordionDetails>
                </MUIAccordion>
                <MUIAccordion>
                    <MUIAccordionSummary expandIcon={<MUIExpandMoreIcon />}>
                        Sales Pilot 5.1
                    </MUIAccordionSummary>
                    <MUIAccordionDetails>
                        <div>
                            <strong>5.1.11</strong>
                            <ul>
                                <li>Added Service Location to Customer Feedback - Closed queue</li>
                                <li>Fixed bug where sometimes API call could fail when opening and leaivng a ticket quickly</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.10</strong>
                            <ul>
                                <li>Moved Product Categories to Purchasing menu</li>
                                <li>Allowed certain Field Operations users to request Sales Liaison meetings</li>
                                <li>Allowed manually re-running eligibility when new orders are in verification</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.9</strong>
                            <ul>
                                <li>Added ability to redeliver an existing order</li>
                                <li>Improved loading performance of dashboard</li>
                                <li>Fixed open by name not clearing when routing tickets</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.8</strong>
                            <ul>
                                <li>Fixed Brightree Item IDs dialog sometimes not loading (again)</li>
                                <li>Fixed Sales Liaison meetings allowing scheduling in current time block</li>
                                <li>Updated inactivity timeout to be global instead of per tab</li>
                                <li>Updates Sales Liaison role to also allow Office Manager role actions</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.7</strong>
                            <ul>
                                <li>Fixed Brightree Item IDs dialog sometimes not loading</li>
                                <li>Fixed Sales &gt; Accounts not loading</li>
                                <li>Fixed Same/Sim sometimes not allowing recheck when not successful</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.6</strong>
                            <ul>
                                <li>Fixed document type not being required before uploading file</li>
                                <li>Updated eligibility check to allow once per calendar month per ticket</li>
                                <li>Fixed eligibility checks sometimes not identifying errors</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.5</strong>
                            <ul>
                                <li>Fixed tech schedule tooltips not showing up</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.4</strong>
                            <ul>
                                <li>Altered insurance verification to be smarter/more conservative</li>
                                <li>Added 2h inactivity timeout</li>
                                <li>Added columns to Customer Feedback queue</li>
                                <li>Fixed queues sometimes opening wrong order</li>
                                <li>Fixed pending preauth queue not setting Open By</li>
                                <li>Updated marketing admin pages to new look & feel</li>
                                <li>Fixed queue statistic export showing blank for 0</li>
                                <li>Fixed queues losing scroll position when expanding groups or refreshing</li>
                                <li>Removed background color from queue group info</li>
                                <li>Updated "Request Paperwork Help" menu to get proper meeting times and accurately represent different time zones</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.3</strong>
                            <ul>
                                <li>Fixed Brightree product lookup sometimes failing behind the scenes</li>
                                <li>Fixed managers not able to see their department if they are a member of another team</li>
                                <li>Added ordinals to queue for dashboard sorting</li>
                                <li>Added member/manager icons to queue dashboard</li>
                                <li>Updated region admin page to new look & feel</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.2</strong>
                            <ul>
                                <li>Restricted dashboard access by department</li>
                                <li>Added history chart to queues for managers and above</li>
                                <li>Fixed orders not opening in new window automatically on deliveries page</li>
                                <li>Fixed queue groups sometimes jumping around on hover</li>
                                <li>Added "Assigned To" column to Complaints Report</li>
                                <li>Fixed deleted files showing up on quotes dialog</li>
                                <li>Added followup note date to add note dialog</li>
                                <li>Added Brightree Product admin page</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.1</strong>
                            <ul>
                                <li>Added Find by Policy # to Patient Search screen</li>
                                <li>Fixed service tickets not opening in new window from deliveries page</li>
                                <li>Fixed saving patient info sometimes displays MBI error</li>
                                <li>Added technician to sales report</li>
                                <li>Updated team users on queue to sort alphabetically</li>
                                <li>Added scheduled delivery date to patient info screen</li>
                                <li>Updated add note box to be expandable; made larger overall</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.1.0</strong>
                            <ul>
                                <li>Fixed service tickets not opening in a new window</li>
                                <li>Replaced dashboard with new queue dashboard</li>
                                <li>Replaced corporate queues with new dynamic queue</li>
                                <li>Moved Sales Pipeline and Month at a Glance to reports dropdown</li>
                                <li>Moved tasks to a separate page</li>
                                <li>Moved followup note to main "Add Note" dialog on ticket view</li>
                                <li>Updated look and feel of main "Add Note" dialog on ticket view</li>
                                <li>Removed individual queue pages from navigation in favor of new dashboard</li>
                                <li>Created new Insurance Group for Followup</li>
                                <li>Added auto tracking of queue statistics</li>
                                <li>Added dark mode for certain pages</li>
                                <li>Removed auto-popup of Quote dialog from ticket view</li>
                                <li>Fixed attachments header not staying fixed when scrolling on patient information page</li>
                            </ul>
                        </div>
                    </MUIAccordionDetails>
                </MUIAccordion>
                <MUIAccordion>
                    <MUIAccordionSummary expandIcon={<MUIExpandMoreIcon />}>
                        Sales Pilot 5.0
                    </MUIAccordionSummary>
                    <MUIAccordionDetails>
                        <div>
                            <strong>5.0.6</strong>
                            <ul>
                                <li>Fixed routing issue with pickup tickets (redux)</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.0.5</strong>
                            <ul>
                                <li>Fixed routing issue with pickup tickets</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.0.4</strong>
                            <ul>
                                <li>Added Technician to Orders page</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.0.3</strong>
                            <ul>
                                <li>Fixed Sales Liaison scheduling allowing overlaps</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.0.2</strong>
                            <ul>
                                <li>Updated access for Sales Liaisons / Business Support</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.0.1</strong>
                            <ul>
                                <li>Fixed accounts not loading after logging in</li>
                                <li>Fixed deliveries map duplicating when reloading page</li>
                                <li>Updated billing page to save state after leaving it</li>
                            </ul>
                        </div>
                        <div>
                            <strong>5.0.0</strong>
                            <ul>
                                <li>Replaced static routing engine with a dynamic one</li>
                            </ul>
                        </div>
                    </MUIAccordionDetails>
                </MUIAccordion>
                <MUIAccordion>
                    <MUIAccordionSummary expandIcon={<MUIExpandMoreIcon />}>
                        Sales Pilot 4.10
                    </MUIAccordionSummary>
                    <MUIAccordionDetails>

                        <div>
                            <strong>4.10.10</strong>
                            <ul>
                                <li>Added update notification</li>
                                <li>Updated orders/tickets to require a reason</li>
                                <li>Changed Sales Liaison meetings to 30 minute increments</li>
                                <li>Fixed issue completing Sales Liaison meetings</li>
                            </ul>
                        </div>
                        <div>
                            <strong>4.10.9</strong>
                            <ul>
                                <li>Improved performance of deliveries map for Rehab Express</li>
                            </ul>
                        </div>
                        <div>
                            <strong>4.10.8</strong>
                            <ul>
                                <li>Added tracking with sessions</li>
                                <li>Various minor bug fixes</li>
                                <li>Automated some steps of user inactivation</li>
                            </ul>
                        </div>
                        <div>
                            <strong>4.10.7</strong>
                            <ul>
                                <li>Fixed Customer Feedback page not loading</li>
                                <li>Removed legacy redirect for old pickup tickets</li>
                                <li>Improved performance of deliveries map</li>
                                <li>Updated some styling on the patient info page</li>
                            </ul>
                        </div>
                        <div>
                            <strong>4.10.6</strong>
                            <ul>
                                <li>Fixed minor issue when creating notes</li>
                            </ul>
                        </div>
                        <div>
                            <strong>4.10.5</strong>
                            <ul>
                                <li>Improved login page on small screens</li>
                                <li>Added better error messages to login page</li>
                                <li>Added instant feedback to Brighttree sync order button</li>
                                <li>Fixed sales report sometimes not loading when selecting all locations</li>
                                <li>Fixed notes sometimes showing up in the wrong order</li>
                                <li>Fixed serial number sometimes not showing up on ticket page</li>
                                <li>Fixed reverse quality log report sometimes not loading when selecting all locations</li>
                            </ul>
                        </div>
                        <div>
                            <strong>4.10.4</strong>
                            <ul>
                                <li>Added NPI field to location dictionary</li>
                                <li>Restored serial number field on products list</li>
                            </ul>
                        </div>
                        <div>
                            <strong>4.10.3</strong>
                            <ul>
                                <li>Fixed bug when opening certain patients</li>
                            </ul>
                        </div>
                        <div>
                            <strong>4.10.2</strong>
                            <ul>
                                <li>Improved initial loading performance after update</li>
                                <li>Fixed inactive users showing up in sales rep dropdown</li>
                                <li>Fixed bug affecting setting of last note date</li>
                                <li>Fixed merging documents not working for pickup tickets</li>
                            </ul>
                        </div>
                        <div>
                            <strong>4.10.1</strong>
                            <ul>
                                <li>Fixed insurance verification button styles</li>
                                <li>Alphabetized sales rep dropdown on orders page</li>
                            </ul>
                        </div>
                        <div>
                            <strong>4.10.0</strong>
                            <ul>
                                <li>Updated serial number capture to include base unit</li>
                                <li>Improved speed of Service Parts Catalog</li>
                            </ul>
                        </div>
                    </MUIAccordionDetails>
                </MUIAccordion>
            </>
        );
    }
}
