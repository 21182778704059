import React from "react";
import PropTypes from "prop-types";
import Dialog from "../Dialog";
import MUIDialogActions from "@mui/material/DialogActions";
import MUITypography from "@mui/material/Typography";
import MUIButton from "@mui/material/Button";
import MUILoadingButton from "@mui/lab/LoadingButton";
import MUIAutocomplete from "@mui/material/Autocomplete";
import MUITextField from "@mui/material/TextField";
import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableCell from '@mui/material/TableCell';
import MUITableContainer from '@mui/material/TableContainer';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import MUIPaper from "@mui/material/Paper";
import MUIGrid from "@mui/material/Grid";
import MUIAlert from "@mui/material/Alert";
import MUITooltip from "@mui/material/Tooltip";
import MUIOpenInNewIcon from "@mui/icons-material/OpenInNew";
import MUILocalShippingIcon from '@mui/icons-material/LocalShipping';
import MUICheckIcon from "@mui/icons-material/Check";
import MUIGreenColor from "@mui/material/colors/green";
import MUIGreyColor from "@mui/material/colors/grey";
import Progress from "../Progress";
import OrderService from "../../Seating/Security/OrderService/orderService";
import moment from "moment";
import Setting from "../../Utility/Setting";
import Location from "../../Utility/Crud/Location";

export default class DialogUpdateServiceQuotePart extends Dialog {
    static contextTypes = {
        locationsIndexed: PropTypes.object,
        orderActivityTypesIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
    };

    componentDidUpdate(prevProps) {
        if (prevProps.serviceQuoteParts.length !== this.props.serviceQuoteParts.length) {
            this.setState({
                serviceQuoteParts: this.props.serviceQuoteParts
            })
        }
        if (prevProps.open !== this.props.open) {
            this.setState({
                updatedServiceQuoteParts: [],
            })
        }
    }

    /**
    * Render header title of dialog
    */
    renderHeader() {
        return (
            <>
                <MUITypography variant="h6" color="textPrimary">
                    Receive Parts
                </MUITypography>
            </>
        );
    }

    /**
     * Render main content of dialog
     */
    renderContent() {
        if (this.state.serviceQuoteParts && this.state.serviceQuoteParts.length > 0) {
            return (
                <>
                    {this.renderOrderLink()}
                    {this.renderServiceQuotePartTable()}
                    {this.renderAlert()}
                </>
            );
        } else {
            return (
                <Progress message="Fetching Data..." />
            )
        }
    }

    /**
       * Render brightree and sales pilot links at top of the dialog
       */
    renderOrderLink() {
        const handleOnClickOpenOrder = (orderId) => {
            OrderService.getOrderActivity(orderId).then((order) => {
                const orderActivityType = Object.values(this.context.orderActivityTypesIndexed)
                    .find(type => type.name.toLowerCase() === order.orderType.toLowerCase());

                const targetUrl = orderActivityType.activityType.toLowerCase() === "ticket"
                    ? `/patientInformation/${order.patientId}/${order.id}`
                    : `/order/${order.id}`;

                window.open(targetUrl, "_blank");
            });
        }

        return (
            this.state.serviceQuoteParts && this.state.serviceQuoteParts.length > 0 ? (
                <MUIGrid container spacing={3}>
                    {this.state.serviceQuoteParts[0]["orderActivityId"] ? (
                        <MUIGrid item xs={"auto"}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleOnClickOpenOrder(this.state.serviceQuoteParts[0]["orderActivityId"])
                                }}
                            >
                                <MUITypography variant={"body2"} sx={{ display: 'flex', alignItems: 'center' }}>
                                    {`Order # ${this.state.serviceQuoteParts[0]["orderActivityId"]}`}
                                    <MUIOpenInNewIcon fontSize="small" sx={{ ml: '5px' }} />
                                </MUITypography>
                            </a>
                        </MUIGrid>
                    ) : null}
                </MUIGrid>
            ) : null
        )
    }

    /**
     * Render all invoice data and claim status dropdown
     */
    renderServiceQuotePartTable() {
        const getLocationOptions = (serviceQuotePart) => {
            let filteredLocations;

            if (serviceQuotePart.locationId === null || serviceQuotePart.locationId === undefined) {
                filteredLocations = [];
            } else if (serviceQuotePart.locationId === 52) {
                filteredLocations = Object.values(this.context.locationsIndexed).filter(location =>
                    location.id === 52
                );
            } else if (serviceQuotePart.locationId === 45) {
                filteredLocations = Object.values(this.context.locationsIndexed).filter(location =>
                    location.id === 45 || location.id === 52
                );
            } else {
                filteredLocations = Object.values(this.context.locationsIndexed).filter(location =>
                    location.id === serviceQuotePart.locationId || location.id === 45 || location.id === 52
                );
            }

            const order = [serviceQuotePart.locationId, 45, 52];

            filteredLocations.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));

            return filteredLocations
                .map((location) => {
                    let title;
                    if (serviceQuotePart.receivedAt !== null ||
                        !Setting.get("queue.prefilters.locationIds") ||
                        Setting.get("queue.prefilters.locationIds").length === 0 ||
                        Setting.get("queue.prefilters.locationIds").length > 1 ||
                        Setting.get("queue.prefilters.locationIds").length === 1 && !Setting.get("queue.prefilters.locationIds").includes(serviceQuotePart.locationId)) {
                        title = Location.getDisplayName(location);
                    } else {
                        if (location.id === 52) { // Purchasing
                            if (
                                Setting.get("queue.prefilters.locationIds") &&
                                Setting.get("queue.prefilters.locationIds").length === 1 &&
                                Setting.get("queue.prefilters.locationIds")[0] === 52 // Purchasing
                            ) {
                                title = "Manufacturer Received"
                            } else {
                                title = "Return to Manufacturer"
                            }
                        } else if (location.id === 45) { // Rehab Express
                            if (
                                Setting.get("queue.prefilters.locationIds") &&
                                Setting.get("queue.prefilters.locationIds").length === 1 &&
                                Setting.get("queue.prefilters.locationIds")[0] === 45 // Rehab Express
                            ) {
                                title = `Keep at ${Location.getDisplayName(location)}`
                            } else {
                                title = `Send to ${Location.getDisplayName(location)}`
                            }
                        } else if (location.id === serviceQuotePart.locationId) {
                            if (
                                Setting.get("queue.prefilters.locationIds") &&
                                Setting.get("queue.prefilters.locationIds").length === 1 &&
                                Setting.get("queue.prefilters.locationIds")[0] === serviceQuotePart.locationId
                            ) {
                                title = `Keep at ${Location.getDisplayName(location)}`
                            }
                        }
                    }

                    return {
                        title: title,
                        value: location.id,
                    };
                });
        };

        const handleUpdateServiceQuotePartChange = (updatedServiceQuotePart) => {
            this.setState((prevState) => {
                const updatedServiceQuoteParts = [...prevState.updatedServiceQuoteParts];
                const updatedServiceQuotePartsFull = [...prevState.serviceQuoteParts];

                const isLocation45or52 = updatedServiceQuotePart.locationId === 45 || updatedServiceQuotePart.locationId === 52;

                const existingPartIndex = updatedServiceQuoteParts.findIndex(
                    (part) => part.id === updatedServiceQuotePart.id
                );

                if (existingPartIndex !== -1) {
                    updatedServiceQuoteParts[existingPartIndex] = {
                        ...updatedServiceQuoteParts[existingPartIndex],
                        ...updatedServiceQuotePart,
                    };
                } else {
                    updatedServiceQuoteParts.push({
                        ...updatedServiceQuotePart,
                    });
                }

                const existingServicePartIndex = updatedServiceQuotePartsFull.findIndex(
                    (part) => part.id === updatedServiceQuotePart.id
                );

                if (existingServicePartIndex !== -1) {
                    updatedServiceQuotePartsFull[existingServicePartIndex] = {
                        ...updatedServiceQuotePartsFull[existingServicePartIndex],
                        isLocation45or52: isLocation45or52,
                        trackingNumber: updatedServiceQuotePart.trackingNumber,
                        rmaNumber: updatedServiceQuotePart.rmaNumber,
                    };
                }

                return {
                    updatedServiceQuoteParts: updatedServiceQuoteParts,
                    serviceQuoteParts: updatedServiceQuotePartsFull,
                };
            });
        };

        const getTrackingLink = (trackingNumber) => {
            const patterns = {
                UPS: /^1Z[0-9A-Z]{16}$/,
                FedEx: /^(96\d{20}|\d{15}|\d{12})$/,
                USPS: /^(94|93|92|95)[0-9]{20}$|^[A-Z]{2}[0-9]{9}US$/,
                DHL: /^\d{10}$/,
            };

            if (patterns.UPS.test(trackingNumber)) {
                return `https://www.ups.com/track?tracknum=${trackingNumber}`;
            } else if (patterns.FedEx.test(trackingNumber)) {
                return `https://www.fedex.com/fedextrack/?tracknumbers=${trackingNumber}`;
            } else if (patterns.USPS.test(trackingNumber)) {
                return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`;
            } else if (patterns.DHL.test(trackingNumber)) {
                return `https://www.dhl.com/en/express/tracking.html?AWB=${trackingNumber}`;
            } else {
                return null;
            }
        }


        return (
            <MUITableContainer component={MUIPaper} sx={{ mt: 2 }}>
                <MUITable stickyHeader size="small">
                    <MUITableHead>
                        <MUITableRow>
                            <MUITableCell>Part Name</MUITableCell>
                            <MUITableCell>Warranty</MUITableCell>
                            <MUITableCell>Qty</MUITableCell>
                            <MUITableCell>Received</MUITableCell>
                            <MUITableCell>Location</MUITableCell>
                        </MUITableRow>
                    </MUITableHead>
                    <MUITableBody>
                        {this.state.serviceQuoteParts && this.state.serviceQuoteParts.length > 0 ? (
                            this.state.serviceQuoteParts.map((serviceQuotePart, idx) => {

                                const updatedPart = this.state.updatedServiceQuoteParts.find(
                                    (updated) => updated.id === serviceQuotePart.id
                                );

                                const mergedPart = {
                                    ...serviceQuotePart,
                                    ...updatedPart,
                                };

                                const selectedOption = getLocationOptions(serviceQuotePart).find(
                                    (option) => option.value === serviceQuotePart.locationId
                                );

                                const isInitialLocation45or52 = serviceQuotePart.locationId === 45 || serviceQuotePart.locationId === 52;

                                return (
                                    <>
                                        <MUITableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={idx}>
                                            <MUITableCell>{serviceQuotePart.partName ? serviceQuotePart.partName : "Unknown"}</MUITableCell>
                                            <MUITableCell>{serviceQuotePart.warranty ? "Yes" : "No"}</MUITableCell>
                                            <MUITableCell>{serviceQuotePart.quantity ? serviceQuotePart.quantity : null}</MUITableCell>
                                            <MUITableCell>{serviceQuotePart.receivedAt ?
                                                (<MUITooltip placement="left" title={
                                                    serviceQuotePart.receivedAt ?
                                                        serviceQuotePart.receivedByUserProfileId ?
                                                            `Received at ${moment.utc(serviceQuotePart.receivedAt).format("M/D/YYYY")} by ${this.context.usersIndexed[serviceQuotePart.receivedByUserProfileId].firstname} ${this.context.usersIndexed[serviceQuotePart.receivedByUserProfileId].lastname}` :
                                                            `Received at ${moment.utc(serviceQuotePart.receivedAt).format("M/D/YYYY")}` :
                                                        null
                                                }>
                                                    <MUICheckIcon fontSize="small" sx={{ color: Setting.get("ui.theme") === "dark" ? MUIGreenColor[300] : MUIGreenColor[700] }} />
                                                </MUITooltip>
                                                ) : null}
                                            </MUITableCell>
                                            <MUITableCell sx={{ width: "300px" }}>
                                                <MUIAutocomplete
                                                    disabled={
                                                        serviceQuotePart.receivedAt !== null ||
                                                        !Setting.get('queue.prefilters.locationIds') ||
                                                        Setting.get('queue.prefilters.locationIds').length === 0 ||
                                                        (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length > 1) ||
                                                        (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length === 1 && !Setting.get('queue.prefilters.locationIds').includes(serviceQuotePart.locationId))}
                                                    options={getLocationOptions(serviceQuotePart)}
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    getOptionLabel={(option) => option.title}
                                                    defaultValue={
                                                        serviceQuotePart.receivedAt !== null ||
                                                            !Setting.get('queue.prefilters.locationIds') ||
                                                            Setting.get('queue.prefilters.locationIds').length === 0 ||
                                                            (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length > 1) ||
                                                            (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length === 1 && !Setting.get('queue.prefilters.locationIds').includes(serviceQuotePart.locationId))
                                                            ? selectedOption : null}
                                                    onChange={(e, newValue) => {
                                                        handleUpdateServiceQuotePartChange({
                                                            ...serviceQuotePart,
                                                            locationId: newValue ? newValue.value : null,
                                                        });
                                                    }}
                                                    renderInput={(params) => {
                                                        return (
                                                            <MUITextField
                                                                {...params}
                                                                size="small"
                                                                variant="standard"
                                                                fullWidth={true}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </MUITableCell>
                                        </MUITableRow>

                                        {/* Additional Fields for RMA # and Tracking # */}
                                        {serviceQuotePart.isLocation45or52 || 
                                        (isInitialLocation45or52 ||
                                            (serviceQuotePart.locationId &&
                                                (serviceQuotePart.locationId === 45 || serviceQuotePart.locationId === 52) &&
                                                (serviceQuotePart.receivedAt !== null ||
                                                    !Setting.get('queue.prefilters.locationIds') ||
                                                    Setting.get('queue.prefilters.locationIds').length === 0 ||
                                                    (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length > 1) ||
                                                    (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length === 1 && !Setting.get('queue.prefilters.locationIds').includes(serviceQuotePart.locationId)))
                                            )
                                        ) ? (
                                            <MUITableRow sx={{ backgroundColor: Setting.get("ui.theme") === "dark" ? MUIGreyColor[900] : MUIGreyColor[200] }}>
                                                <MUITableCell></MUITableCell>
                                                <MUITableCell sx={{ pt: '4px', pb: '4px' }} colSpan={3}>
                                                    <MUITextField
                                                        label="RMA #"
                                                        defaultValue={serviceQuotePart.rmaNumber || ''}
                                                        onChange={(e) =>
                                                            handleUpdateServiceQuotePartChange({
                                                                ...mergedPart,
                                                                rmaNumber: e.target.value,
                                                            })
                                                        }
                                                        size="small"
                                                        variant="standard"
                                                        fullWidth
                                                        disabled={serviceQuotePart.receivedAt !== null ||
                                                            !Setting.get('queue.prefilters.locationIds') ||
                                                            Setting.get('queue.prefilters.locationIds').length === 0 ||
                                                            (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length > 1) ||
                                                            (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length === 1 && !Setting.get('queue.prefilters.locationIds').includes(serviceQuotePart.locationId))}
                                                        sx={{
                                                            margin: 0,
                                                            '& .MuiInputBase-root': {
                                                                fontSize: '0.875rem',
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                fontSize: '0.75rem',
                                                            },
                                                        }}
                                                    />
                                                </MUITableCell>
                                                <MUITableCell sx={{ pt: '4px', pb: '4px' }}>
                                                    <MUITextField
                                                        label="Tracking #"
                                                        defaultValue={serviceQuotePart.trackingNumber || ''}
                                                        onChange={(e) =>
                                                            handleUpdateServiceQuotePartChange({
                                                                ...mergedPart,
                                                                trackingNumber: e.target.value,
                                                            })
                                                        }
                                                        size="small"
                                                        variant="standard"
                                                        fullWidth
                                                        disabled={serviceQuotePart.receivedAt !== null ||
                                                            !Setting.get('queue.prefilters.locationIds') ||
                                                            Setting.get('queue.prefilters.locationIds').length === 0 ||
                                                            (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length > 1) ||
                                                            (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length === 1 && !Setting.get('queue.prefilters.locationIds').includes(serviceQuotePart.locationId))}
                                                        sx={{
                                                            margin: 0,
                                                            '& .MuiInputBase-root': {
                                                                fontSize: '0.875rem',
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                fontSize: '0.75rem',
                                                            },
                                                        }}
                                                        InputProps={{
                                                            endAdornment: (() => {
                                                                const trackingUrl = getTrackingLink(serviceQuotePart.trackingNumber);
                                                                return trackingUrl ? (
                                                                    <MUITooltip title="Track Shipment">
                                                                        <MUIButton
                                                                            onClick={() => {
                                                                                window.open(trackingUrl, '_blank');
                                                                            }}
                                                                            sx={{
                                                                                minWidth: 0,
                                                                                padding: 0,
                                                                                color: Setting.get("ui.theme") === "dark" ? MUIGreenColor[300] : MUIGreenColor[700],
                                                                            }}
                                                                        >
                                                                            <MUILocalShippingIcon fontSize="small" />
                                                                        </MUIButton>
                                                                    </MUITooltip>
                                                                ) : null;
                                                            })(),
                                                        }}
                                                    />
                                                </MUITableCell>
                                            </MUITableRow>
                                        ) : null}
                                    </>
                                )
                            })
                        ) : null}
                    </MUITableBody>
                </MUITable>
            </MUITableContainer>
        )
    }

    /**
     * Render info alert.
     */
    renderAlert() {
        const cannotReceiveParts = !Setting.get('queue.prefilters.locationIds') ||
            Setting.get('queue.prefilters.locationIds').length === 0 ||
            (Setting.get('queue.prefilters.locationIds') && Setting.get('queue.prefilters.locationIds').length > 1);

        return (
            <MUIAlert severity={cannotReceiveParts ? "warning" : "info"} sx={{ mt: 1 }}>
                {cannotReceiveParts ?
                    "Set your prefilter to a single location to receive parts." :
                    "I certify that I have received these parts."}
            </MUIAlert>
        )
    }

    /**
     * Render cancel and confirm button actions of dialog.
     */
    renderActions() {
        const isUpdateButtonEnabled = () => {
            if (!this.state.updatedServiceQuoteParts || this.state.updatedServiceQuoteParts === 0 || !this.state.serviceQuoteParts || this.state.serviceQuoteParts === 0) {
                return false;
            }

            const eligiblePartsForUpdate = this.state.serviceQuoteParts.filter((part) =>
                part.receivedAt === null &&
                (Setting.get("queue.prefilters.locationIds") && Setting.get("queue.prefilters.locationIds").length === 1 && Setting.get("queue.prefilters.locationIds").includes(part.locationId))
            );

            const eligiblePartsHaveUpdateMatch = eligiblePartsForUpdate.length > 0 && eligiblePartsForUpdate.every(part =>
                this.state.updatedServiceQuoteParts.some(updatedPart => updatedPart.id === part.id && updatedPart.locationId !== null)
            );

            return !eligiblePartsHaveUpdateMatch;
        }

        return (
            <MUIDialogActions spacing={2}>
                <MUIButton variant="text" onClick={this.props.onClose}>
                    Cancel
                </MUIButton>
                <MUILoadingButton
                    variant="contained"
                    disabled={isUpdateButtonEnabled()}
                    loading={this.props.loading}
                    onClick={() => this.props.onConfirm(this.state.updatedServiceQuoteParts)}>
                    Confirm
                </MUILoadingButton>
            </MUIDialogActions>
        );
    }

    /**
     * @returns {string} The max width of the dialog.
     */
    getMaxWidth() {
        return "md";
    }
}
