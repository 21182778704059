import Crud from "../Crud";
import MUIBrownColor from "@mui/material/colors/brown";

/**
 * Static helper functions related to ServicePartCatalog.
 */
export default class ServicePartCatalog extends Crud {

    /**
     * @param {object} servicePartCatalog The servicePartCatalog object.
     * @returns The display name of the servicePartCatalog.
     */
    static getDisplayName(servicePartCatalog) {
        return servicePartCatalog ? servicePartCatalog.description : null;
    }

    /**
     * Determines if a productSubType matches a search query.
     *
     * @param {object} servicePartCatalog The servicePartCatalog to test.
     * @param {string} query The search query.
     * @returns {boolean} Whether or not the servicePartCatalog matches the query.
     */
    static match(servicePartCatalog, query) {
        return (
            (servicePartCatalog.description !== null && servicePartCatalog.description.toLowerCase().includes(query)) ||
            servicePartCatalog.id === +query
        );
    }

    /**
     * @param {array} servicePartCatalogs The list to sort.
     * @returns {array} The sorted list.
     */
    static sort(servicePartCatalogs) {
        return servicePartCatalogs.sort((a, b) => {
            return (a.description).localeCompare(b.description);
        });
    }

    /**
     * @returns {string} The color of this object.
     */
    static getBaseColor() {
        return MUIBrownColor;
    }

    /**
     * @returns {string} The icon name for this object.
     */
    static getIconName() {
        return "Inventory";
    }
}