import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router } from "react-router-dom";
import Select from "react-select";
import { MainProvider } from './MainContext';
import Routes from "./Routes";
import Setting from "./Utility/Setting";
import PODeliveryUpdate from "./Seating/PurchaseQueue/PODeliveryUpdate";
import InventoryRequest from "./Seating/PurchaseQueue/inventoryRequest";
import ReverseQualityService from "./Seating/Settings/reverseQualityService";
import background from "./Asset/Image/background.jpg";
import {
    MDBBtn,
    MDBCol,
    MDBInput,
    MDBModal,
    MDBRow,
    ModalBody,
    toast,
} from "mdbreact";
import "./index.css";
import MUISnackbar from '@mui/material/Snackbar';
import MUIAlert from '@mui/material/Alert';
import MUIButton from '@mui/material/Button';
import { SnackbarProvider as NotistackSnackbarProvider } from "notistack";
import { AdapterMoment as MUIAdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider as MUILocalizationProvider  } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment-timezone";
import AppBar from "./Component/AppBar";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pca: null,
            collapseID: "",
            allThemes: [
                "Gorgeous Contrast",
                "Alternate Contrast",
                "Corporate",
                "Bold",
                "Plum",
                "Indigo",
                "Umber",
            ],
            currentUser: {},
            company: "",
            companyName: "",
            poDeliveryUpdate: false,
            inventoryRequestOpen: false,
            insuranceTypes: [],
            insuranceSubTypes: [],
            productTypes: [],
            productSubTypes: [],
            searchText: "",
            reasons: [],
            therapists: [],
            salesReps: [],
            accounts: [],
            myAccounts: [],
            allLocations: [],
            internalLocations: [],
            allRegions: [],
            allUsers: [],
            userRefs: [],
            allActiveUsers: [],
            allLiaisons: [],
            allPCR: [],
            allAtp: [],
            thirdPartyUsers: [],
            thirdPartyUsersLoaded: false,
            cardTypes: [],
            expenseTypes: [],
            documentTypes: [],
            contactTypes: [],
            accountTypes: [],
            patientCommunicationTriggerDefs: [],
            techs: [],
            marketingCampaigns: [],
            complaintCategories: [],
            complaintReasons: [],
            allVendors: [],
            allBaseUnits: [],
            allBaseUnitTypes: [],
            productOverrides: [],
            fullLiaisonKeys: [],
            orderModalOpen: false,
            orderErrorMessage: "",
            orderFound: false,
            orderId: "",
            stateSalesTax: [],
            reverseQualityCategories: [],
            reverseQualityCategoriesRefs: [],
            reverseQualityReasons: [],
            rqModalOpen: false,
            rqObject: {},
            rqOrderId: "",
            updateNotesModalOpen: false,

            /**
             * This section is for new context entries that are now indexed by
             * their primary key. It may include some duplications of the above
             * data for backwards compatability.
             *
             * Nomenclature is the plural name of the object followed by the
             * word "indexed".
             *
             * Note that this should be limited to one entry per object type.
             * For example, do not use "allUsers" and "allActiveUsers". Those
             * distinctions should be handled with appropriate filtering.
             */
            updateIndexed: (key, data) => {
                this.setState(prevState => ({
                    [key]: {
                        ...prevState[key],
                        [data.id]: data
                    }
                }));
                window.sessionStorage.removeItem(key);
            },
            deleteIndexed: (key, id) => {
                this.setState(prevState => {
                    const { [id]: deleted, ...rest } = prevState[key];
                    return { [key]: rest };
                });
                window.sessionStorage.removeItem(key);
            },
            setCurrentUser: (user) => {
                this.setState({ currentUser: user });
                window.sessionStorage.setItem("currentUser", JSON.stringify(user));
            },
            setCompanyName: (company) => {
                this.setState({ companyName: company });
                window.sessionStorage.setItem("companyName", JSON.stringify(company));
            },
            termsIndexed: {},
            hcpcsCodesIndexed: {},
            distributionReasonsIndexed: {},
            userProductionTypesIndexed: {},
            usersIndexed: {},
            departmentsIndexed: {},
            teamsIndexed: {},
            teamUsersIndexed: {},
            teamQueuesIndexed: {},
            queueRoutesIndexed: {},
            queuesIndexed: {},
            orderActivityTypesIndexed: {},
            issueStatusesIndexed: {},
            orderStatusesIndexed: {},
            orderStatusReasonsIndexed: {},
            insuranceTypesIndexed: {},
            insuranceSubTypesIndexed: {},
            serviceReasonsIndexed: {},
            baseUnitTypesIndexed: {},
            baseUnitsIndexed: {},
            vendorsIndexed: {},
            colorsIndexed: {},
            locationsIndexed: {},
            regionsIndexed: {},
            productTypesIndexed: {},
            productSubTypesIndexed: {},
            issueCategoriesIndexed: {},
            issueReasonsIndexed: {},
            salesPcrLinksIndexed: {},
            marketingCampaignsIndexed: {},
            marketingCampaignCategoriesIndexed: {},
            userprofileLocationsIndexed: {},
            accountTypesIndexed: {},
            contactTypesIndexed: {},
            documentTypesIndexed: {},
            reverseQualityReasonsIndexed: {},
            creditCardTypesIndexed: {},
            expenseTypesIndexed: {},
            servicePartCatalogsIndexed: {},
            newVersionAvailable: false,
            manifestVersion: null,
        };

        // Check for a new version of the app by parsing meta.json on the server.
        const checkForUpdate = async () => {
            try {
                const response = await fetch('/manifest.json', { cache: 'no-store' });
                const manifest = await response.json();

                if (manifest.version) {
                    if (
                        window.salesPilot.meta.version.localeCompare(
                            manifest.version,
                            undefined,
                            {
                                numeric: true,
                                sensitivity: 'base'
                            }
                        ) < 0
                    ) {
                        if (new URL(window.location).pathname === "/") {
                            // If you're on the login screen when an update hits, force a reload.
                            window.location.reload();
                        } else {
                            this.setState({
                                newVersionAvailable: true,
                                manifestVersion: manifest.version
                            });
                        }
                    }
                }
            }
            catch (e) {
                // For some reason this fetch fails a lot. Not sure if it's just
                // because it's happening right on page load or what. For now
                // ignoring errors. If this check does not fix the version lag
                // we're seeing I'll re-enable the exception here and deal with
                // it. - JZ
            }
        };

        const checkForInactivity = () => {
            if (
                window.salesPilot.meta.environment === "production" &&
                moment().diff(moment(Setting.get("meta.lastActivity", false)), "minutes") > 60 &&
                new URL(window.location).pathname !== "/"
            ) {
                Setting.clear("session", window.sessionStorage);
                Setting.clear("session", window.localStorage);
                Setting.set("loginError", "You have been logged out due to inactivity.");
                Setting.set("loginErrorExpiration", moment().add(1, "hour").toISOString());
                window.sessionStorage.clear();
                window.location.href = "/";
            }
        };

        // Check for updates every hour
        if (!window.checkForUpdateInterval) {
            window.checkForUpdateInterval = window.setInterval(checkForUpdate, 3600000);
            checkForUpdate();
        }

        // Check for inactivity every minute
        if (!window.checkForInactivityInterval) {
            window.checkForInactivityInterval = window.setInterval(checkForInactivity, 60000);
        }
    }



    static childContextTypes = {
        loaded: PropTypes.bool,
        setContextItem: (key, value) => { },
        company: PropTypes.string,
        companyName: PropTypes.string,
        currentUser: PropTypes.object,
        insuranceTypes: PropTypes.array,
        insuranceSubTypes: PropTypes.array,
        productTypes: PropTypes.array,
        productSubTypes: PropTypes.array,
        reasons: PropTypes.array,
        therapists: PropTypes.array,
        salesReps: PropTypes.array,
        accounts: PropTypes.array,
        myAccounts: PropTypes.array,
        allLocations: PropTypes.array,
        internalLocations: PropTypes.array,
        allRegions: PropTypes.array,
        allUsers: PropTypes.array,
        userRefs: PropTypes.array,
        allActiveUsers: PropTypes.array,
        allLiaisons: PropTypes.array,
        allPCR: PropTypes.array,
        allAtp: PropTypes.array,
        thirdPartyUsers: PropTypes.array,
        thirdPartyUsersLoaded: PropTypes.bool,
        patientCommunicationTriggerDefs: PropTypes.array,
        cardTypes: PropTypes.array,
        expenseTypes: PropTypes.array,
        documentTypes: PropTypes.array,
        contactTypes: PropTypes.array,
        accountTypes: PropTypes.array,
        techs: PropTypes.array,
        marketingCampaigns: PropTypes.array,
        complaintCategories: PropTypes.array,
        complaintReasons: PropTypes.array,
        allVendors: PropTypes.array,
        allBaseUnits: PropTypes.array,
        allBaseUnitTypes: PropTypes.array,
        productOverrides: PropTypes.array,
        fullLiaisonKeys: PropTypes.array,
        timezoneVariation: PropTypes.number,
        stateSalesTax: PropTypes.array,
        reverseQualityCategories: PropTypes.array,
        reverseQualityReasons: PropTypes.array,

        // Context entries indexed by primary key.
        updateIndexed: PropTypes.func,
        deleteIndexed: PropTypes.func,
        setCurrentUser: PropTypes.func,
        setCompanyName: PropTypes.func,
        termsIndexed: PropTypes.object,
        hcpcsCodesIndexed: PropTypes.object,
        distributionReasonsIndexed: PropTypes.object,
        userProductionTypesIndexed: PropTypes.object,
        usersIndexed: PropTypes.object,
        departmentsIndexed: PropTypes.object,
        teamsIndexed: PropTypes.object,
        teamUsersIndexed: PropTypes.object,
        teamQueuesIndexed: PropTypes.object,
        queueRoutesIndexed: PropTypes.object,
        queuesIndexed: PropTypes.object,
        orderActivityTypesIndexed: PropTypes.object,
        issueStatusesIndexed: PropTypes.object,
        orderStatusesIndexed: PropTypes.object,
        orderStatusReasonsIndexed: PropTypes.object,
        insuranceTypesIndexed: PropTypes.object,
        insuranceSubTypesIndexed: PropTypes.object,
        serviceReasonsIndexed: PropTypes.object,
        baseUnitTypesIndexed: PropTypes.object,
        baseUnitsIndexed: PropTypes.object,
        vendorsIndexed: PropTypes.object,
        colorsIndexed: PropTypes.object,
        locationsIndexed: PropTypes.object,
        regionsIndexed: PropTypes.object,
        productTypesIndexed: PropTypes.object,
        productSubTypesIndexed: PropTypes.object,
        issueCategoriesIndexed: PropTypes.object,
        issueReasonsIndexed: PropTypes.object,
        salesPcrLinksIndexed: PropTypes.object,
        marketingCampaignsIndexed: PropTypes.object,
        marketingCampaignCategoriesIndexed: PropTypes.object,
        userprofileLocationsIndexed: PropTypes.object,
        accountTypesIndexed: PropTypes.object,
        contactTypesIndexed: PropTypes.object,
        documentTypesIndexed: PropTypes.object,
        reverseQualityReasonsIndexed: PropTypes.object,
        creditCardTypesIndexed: PropTypes.object,
        expenseTypesIndexed: PropTypes.object,
        servicePartCatalogsIndexed: PropTypes.object,
    };

    setContextItem(key, value) {
        this.setState({
            [key]: value,
        });
    }

    getChildContext() {
        return {
            loaded: this.state.loaded,
            setContextItem: this.setContextItem.bind(this),
            company: this.state.company,
            companyName: this.state.companyName,
            currentUser: this.state.currentUser,
            insuranceTypes: this.state.insuranceTypes,
            insuranceSubTypes: this.state.insuranceSubTypes,
            productTypes: this.state.productTypes,
            productSubTypes: this.state.productSubTypes,
            reasons: this.state.reasons,
            therapists: this.state.therapists,
            salesReps: this.state.salesReps,
            accounts: this.state.accounts,
            myAccounts: this.state.myAccounts,
            allLocations: this.state.allLocations,
            internalLocations: this.state.internalLocations,
            allRegions: this.state.allRegions,
            allUsers: this.state.allUsers,
            allActiveUsers: this.state.allActiveUsers,
            userRefs: this.state.userRefs,
            allLiaisons: this.state.allLiaisons,
            allPCR: this.state.allPCR,
            allAtp: this.state.allAtp,
            thirdPartyUsers: this.state.thirdPartyUsers,
            thirdPartyUsersLoaded: this.state.thirdPartyUsersLoaded,
            patientCommunicationTriggerDefs: this.state.patientCommunicationTriggerDefs,
            cardTypes: this.state.cardTypes,
            expenseTypes: this.state.expenseTypes,
            documentTypes: this.state.documentTypes,
            contactTypes: this.state.contactTypes,
            accountTypes: this.state.accountTypes,
            techs: this.state.techs,
            marketingCampaigns: this.state.marketingCampaigns,
            complaintCategories: this.state.complaintCategories,
            complaintReasons: this.state.complaintReasons,
            allVendors: this.state.allVendors,
            allBaseUnits: this.state.allBaseUnits,
            allBaseUnitTypes: this.state.allBaseUnitTypes,
            productOverrides: this.state.productOverrides,
            fullLiaisonKeys: this.state.fullLiaisonKeys,
            timezoneVariation: this.state.timezoneVariation,
            stateSalesTax: this.state.stateSalesTax,
            reverseQualityCategories: this.state.reverseQualityCategories,
            reverseQualityReasons: this.state.reverseQualityReasons,

            // Context entries indexed by primary key.
            updateIndexed: this.state.updateIndexed,
            deleteIndexed: this.state.deleteIndexed,
            setCurrentUser: this.state.setCurrentUser,
            setCompanyName: this.state.setCompanyName,
            termsIndexed: this.state.termsIndexed,
            hcpcsCodesIndexed: this.state.hcpcsCodesIndexed,
            distributionReasonsIndexed: this.state.distributionReasonsIndexed,
            userProductionTypesIndexed: this.state.userProductionTypesIndexed,
            usersIndexed: this.state.usersIndexed,
            departmentsIndexed: this.state.departmentsIndexed,
            teamsIndexed: this.state.teamsIndexed,
            teamUsersIndexed: this.state.teamUsersIndexed,
            teamQueuesIndexed: this.state.teamQueuesIndexed,
            queueRoutesIndexed: this.state.queueRoutesIndexed,
            queuesIndexed: this.state.queuesIndexed,
            orderActivityTypesIndexed: this.state.orderActivityTypesIndexed,
            issueStatusesIndexed: this.state.issueStatusesIndexed,
            orderStatusesIndexed: this.state.orderStatusesIndexed,
            orderStatusReasonsIndexed: this.state.orderStatusReasonsIndexed,
            insuranceTypesIndexed: this.state.insuranceTypesIndexed,
            insuranceSubTypesIndexed: this.state.insuranceSubTypesIndexed,
            serviceReasonsIndexed: this.state.serviceReasonsIndexed,
            baseUnitTypesIndexed: this.state.baseUnitTypesIndexed,
            baseUnitsIndexed: this.state.baseUnitsIndexed,
            vendorsIndexed: this.state.vendorsIndexed,
            colorsIndexed: this.state.colorsIndexed,
            locationsIndexed: this.state.locationsIndexed,
            regionsIndexed: this.state.regionsIndexed,
            productTypesIndexed: this.state.productTypesIndexed,
            productSubTypesIndexed: this.state.productSubTypesIndexed,
            issueCategoriesIndexed: this.state.issueCategoriesIndexed,
            issueReasonsIndexed: this.state.issueReasonsIndexed,
            salesPcrLinksIndexed: this.state.salesPcrLinksIndexed,
            userprofileLocationsIndexed: this.state.userprofileLocationsIndexed,
            marketingCampaignsIndexed: this.state.marketingCampaignsIndexed,
            marketingCampaignCategoriesIndexed: this.state.marketingCampaignCategoriesIndexed,
            accountTypesIndexed: this.state.accountTypesIndexed,
            contactTypesIndexed: this.state.contactTypesIndexed,
            documentTypesIndexed: this.state.documentTypesIndexed,
            reverseQualityReasonsIndexed: this.state.reverseQualityReasonsIndexed,
            creditCardTypesIndexed: this.state.creditCardTypesIndexed,
            expenseTypesIndexed: this.state.expenseTypesIndexed,
            servicePartCatalogsIndexed: this.state.servicePartCatalogsIndexed,
        };
    }

    render() {
        return (
            <Router>
                <MUILocalizationProvider dateAdapter={MUIAdapterMoment}>
                    <NotistackSnackbarProvider autoHideDuration={5000} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} maxSnack={3} />
                    <div className="flyout App-background mainBackground" style={{ backgroundSize: 'cover', backgroundAttachment: "fixed", backgroundImage: `url(${background})` }}>
                        {this.state.loaded && this.renderAppBar()}

                        <MainProvider value={this.state}>
                            <main style={{marginTop: this.state.loaded ? '50px' : '0'}}>
                                <Routes />
                            </main>
                        </MainProvider>

                        {this.state.loaded && this.renderReverseQualityModal()}
                        {this.state.loaded && this.renderInventoryRequestModal()}
                        {this.state.loaded && this.renderPODeliveryUpdateModal()}
                    </div>
                    {this.state.loaded && this.renderNewVersionSnackbar()}
                </MUILocalizationProvider>
            </Router>
        );
    }
























































    renderAppBar() {
        const handleOnSearchChange = (orderId) => {
            this.setState({
                orderId: orderId,
            });
        }

        const handleCloseDialog = () => {
            this.setState({
                rqModalOpen: false,
                inventoryRequestOpen: false,
                poDeliveryUpdateOpen: false
            });
        }

        return (
            <AppBar
                onChange={handleOnSearchChange.bind(this)}
                /** TODO: Refactor RQModal && InventoryRequestModal &&
                  * PODeliveryUpdateModal into a SP Component and import
                  * components directly inside of AppBar. Keeping the logic in
                  * this file for now due to the complexity of refactoring.
                  */
                onCloseDialog={handleCloseDialog.bind(this)}
                modalOpen={this.state.rqModalOpen === true || this.state.inventoryRequestOpen === true || this.state.poDeliveryUpdateOpen === true}
                openReportReverseQualityModal={() => this.setState({ rqModalOpen: true })}
                openInventoryRequestModal={() => this.setState({ inventoryRequestOpen: true })}
                openPODeliveryUpdateModal={() => this.setState({ poDeliveryUpdateOpen: true })}
            />
        );
    }

    renderNewVersionSnackbar() {
        return (
            <MUISnackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={this.state.newVersionAvailable}
            >
                <MUIAlert
                    severity="info"
                    variant="filled"
                    action={
                        <MUIButton
                            color="inherit"
                            size="small"
                            onClick={() => { window.location.reload(); }}
                        >
                            FINISH UPDATE
                        </MUIButton>
                    }
                >{`Sales Pilot ${this.state.manifestVersion} available.`}</MUIAlert>
            </MUISnackbar>
        );
    }














    closeInventoryRequest() {
        this.setState({
            inventoryRequestOpen: false,
        });
    }

    renderInventoryRequestModal() {
        return (
            <MDBModal
                size="lg"
                centered
                isOpen={this.state.inventoryRequestOpen}
                toggle={() => {
                    this.setState({
                        inventoryRequestOpen: false,
                    });
                }}
            >
                <InventoryRequest onClose={this.closeInventoryRequest.bind(this)} />
            </MDBModal>
        );
    }

    renderPODeliveryUpdateModal() {
        return (
            <MDBModal
                size="md"
                centered
                isOpen={this.state.poDeliveryUpdateOpen}
                toggle={() => {
                    this.setState({
                        poDeliveryUpdateOpen: false,
                    });
                }}
            >
                <PODeliveryUpdate onClose={this.closePODeliveryUpdate.bind(this)} />
            </MDBModal>
        );
    }

    closePODeliveryUpdate() {
        this.setState({
            poDeliveryUpdateOpen: false,
        });
    }

    handleRQUserSelected = (e) => {
        let obj = this.state.rqObject;

        obj.rqUser = e.value;
        obj.rqUserId = e.value.id;
        this.setState({
            rqObject: obj,
        });
    };

    handleRqCategorySelected = (e) => {
        let obj = this.state.rqObject;

        obj.rqQualityCategory = e.value;
        obj.rqQualityCategoryId = e.value.id;

        let r = JSON.parse(JSON.stringify(this.state.reverseQualityReasons)),
            ref = [];

        r = r && r.length > 0 ? r.filter(v => {
            return v.reverseQualityCategoryId === e.value.id;
        }) : r;

        if (r && r.length > 0) {
            r.forEach(nv => {
                ref.push({
                    label: nv.name,
                    value: {
                        id: nv.id,
                        name: nv.name,
                    },
                });
            });
        }

        this.setState({
            rqObject: obj,
            currentReverseQualityReasonRefs: ref
        });
    };

    handleRqReasonSelected = (e) => {
        let obj = this.state.rqObject;

        obj.rqQualityReason = e.value;
        obj.rqQualityReasonId = e.value.id;
        this.setState({
            rqObject: obj,
        });
    };

    handleRqNote(e) {
        let obj = this.state.rqObject;

        obj.notes = e.target.value;

        this.setState({
            rqObject: obj,
        });
    }

    submitRqCategory() {
        let currentUser = this.state.currentUser,
            obj = this.state.rqObject;

        if (!obj.notes) {
            return toast.warn("You must enter some notes");
        } else if (!obj.orderId) {
            return toast.warn("You must enter an order id");
        } else if (!obj.rqUser) {
            return toast.warn("You must select a user");
        } else if (!obj.rqQualityCategory) {
            return toast.warn("You must select a category");
        } else if (!obj.rqQualityReason) {
            return toast.warn("You must select a reason");
        }

        obj.rqReportedBy = { name: currentUser.username, id: currentUser.id };
        obj.rqReportedById = currentUser.id;
        obj.rqReportedOn = new Date();

        ReverseQualityService.createReverseQualityLog(obj)
            .then((r) => {
                this.setState({
                    rqModalOpen: false,
                    rqObject: {},
                });
                toast.success("RQ Submitted!");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    renderReverseQualityModal() {
        return (
            <MDBModal
                isOpen={this.state.rqModalOpen}
                centered
                size="lg"
                toggle={() => {
                    this.setState({
                        rqModalOpen: false,
                        rqObject: {},
                    });
                }}
            >
                <MDBRow style={{ justifyContent: "center" }}>
                    <span
                        style={{ fontSize: 20, padding: 10, textDecoration: "underline", color: "black" }}
                    >
                        Report Reverse Quality
                    </span>
                </MDBRow>

                <ModalBody style={{ backgroundColor: "#FFFFFF", padding: 20 }}>
                    <MDBRow style={{ justifyContent: "center" }}>
                        <MDBCol size="12" md="5">
                            <label style={{color: "black"}}> Order Id </label>
                            <MDBInput
                                containerClass={"noMargin"}
                                outline
                                type="number"
                                valueDefault={this.state.rqOrderId}
                                onChange={(e) => {
                                    let obj = this.state.rqObject;
                                    obj.orderId = parseInt(e.target.value);

                                    this.setState({
                                        rqObject: obj,
                                        rqOrderId: e.target.value,
                                    });
                                }}
                            />
                        </MDBCol>
                        <MDBCol size="12" md="5">
                            <label style={{color: "black"}}> User Being Reported </label>
                            <Select
                                options={this.state.userRefs}
                                onChange={this.handleRQUserSelected.bind(this)}
                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                    }),
                                }}
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow style={{ justifyContent: "center" }}>
                        <MDBCol size="12" md="5">
                            <label style={{color: "black"}}> RQ Category </label>
                            <Select
                                options={this.state.reverseQualityCategoriesRefs}
                                onChange={this.handleRqCategorySelected.bind(this)}
                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                    }),
                                }}
                            />
                        </MDBCol>

                        <MDBCol size="12" md="5">
                            <label style={{color: "black"}}> RQ Reason </label>
                            <Select
                                options={this.state.currentReverseQualityReasonRefs}
                                onChange={this.handleRqReasonSelected.bind(this)}
                                styles={{
                                    option: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        color: 'black',
                                    }),
                                }}
                            />
                        </MDBCol>

                        <MDBCol size="10">
                            <MDBInput
                                style={{ border: "1px solid black", textAlign: "left" }}
                                type="textarea"
                                label="Enter notes.."
                                rows="4"
                                cols="50"
                                valueDefault={this.state.rqObject.notes}
                                onChange={this.handleRqNote.bind(this)}
                            />

                            <MDBBtn
                                color={"indigo"}
                                className="float-right"
                                onClick={() => this.submitRqCategory()}
                            >
                                Submit
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </ModalBody>
            </MDBModal>
        );
    }

    // isEmpty(obj) {
    //     for (var key in obj) {
    //         if (obj.hasOwnProperty(key)) return false;
    //     }
    //     return true;
    // }

    // renderEquipment() {
    //     let end = new Date(),
    //         start = null,
    //         statusName = "ReadyToDeliver",
    //         targetId = 8;
    //     return (
    //         <Route
    //             render={({ history }) => (
    //                 <DropdownItem
    //                     onClick={(e) => {
    //                         e.stopPropagation();
    //                         var pathname = "/order";

    //                         history.push({
    //                             pathname: [pathname],
    //                             state: {
    //                                 shouldOverride: true,
    //                                 filters: {
    //                                     statuses: [statusName],
    //                                     startDate: start,
    //                                     endDate: end,
    //                                     reasonIds: [targetId],
    //                                     locType: "Sales",
    //                                     userprofileLocations: this.state.currentUser.userprofileLocations,
    //                                 },
    //                             },
    //                         });
    //                     }}
    //                 >
    //                     <div style={{ fontWeight: 300 }}>
    //                         Equipment Ordered
    //                     </div>
    //                 </DropdownItem>
    //             )}
    //         />
    //     );
    // }










}

export default App;
