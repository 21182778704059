import api from "../../../../Seating/Security/api";
import DialogUpdateServiceQuotePart from "../../../Dialog/DialogUpdateServiceQuotePart";
import CardQueueDataGrid from "../CardQueueDataGrid";
import { enqueueSnackbar as NotistackEnqueueSnackbar } from "notistack";

export default class CardQueueDataGridServiceQuotePart extends CardQueueDataGrid {
    columns = {
        "service_quote_part.id": {
            type: "number",
            name: "Part #",
            width: 130,
        },
        "service_quote_part.location_id": {
            type: "location",
            name: "Location",
            width: 140,
        },
        "service_quote_part.order_activity_id": {
            type: "number",
            name: "Order #",
            width: 120,
        },
        "service_quote_part.quantity": {
            type: "number",
            name: "Qty",
            width: 120,
        },
        "service_quote_part.received_at": {
            type: "date",
            name: "Received At",
            width: 180,
        },
        "service_quote_part.received_by_userprofile_id": {
            type: "user",
            name: "Received By User",
            width: 180,
        },
        "service_quote_part.service_part_catalog_id": {
            type: "partName",
            name: "Part Name",
            width: 240,
        },
        "service_quote_part.warranty": {
            type: "boolean",
            name: "Warranty",
            width: 120,
        },
        "service_quote_part.order_activity.technician_id": {
            type: "user",
            name: "Technician",
            width: 120,
        },
        "out_of_bounds": {
            type: "boolean",
            name: "OOB",
            width: 80,
        },
        "order_activity.id": {
            type: "Number",
            name: "Order ID",
            width: 150,
        },
    }
    constructor(props) {
        super(props);

        this.state.serviceQuoteParts = [];
        this.state.dialogUpdateServiceQuotePartOpen = false;
        this.state.isUpdating = false;
    }

    /**
    * Handles the saving of updated invoice changes.
    */
    handleOnSaveServiceQuotePartChanges(serviceQuoteParts) {
        this.setState({
            isUpdating: true,
        })

        const promises = [];


        const originalServiceQuoteParts = new Map(
            this.state.serviceQuotePartsOriginal.map((serviceQuotePart) => [
                serviceQuotePart["service_quote_part.id"],
                serviceQuotePart
            ])
        );

        serviceQuoteParts.forEach((serviceQuotePart) => {
            const serviceQuotePartOriginal = originalServiceQuoteParts.get(serviceQuotePart.id);
            if (serviceQuotePartOriginal) {
                if (serviceQuotePart.locationId === serviceQuotePartOriginal["service_quote_part.location_id"]) {
                    serviceQuotePart.receivedAt = new Date().toISOString();
                    promises.push(api.send("PUT", "/serviceQuotePart", serviceQuotePart))
                } else if (serviceQuotePart.locationId !== serviceQuotePartOriginal["service_quote_part.location_id"]) {
                    serviceQuotePart.locationId = serviceQuotePart.locationId;
                    promises.push(api.send("PUT", "/serviceQuotePart", serviceQuotePart))
                }
            }
        });
        
        Promise.all(promises).then(() => {
            NotistackEnqueueSnackbar("Service Quote Part updated successfully!", { variant: "success" });
            this.setState({
                isUpdating: false,
                dialogUpdateServiceQuotePartOpen: false,
                serviceQuoteParts: [],
                serviceQuotePartsOriginal: [],
            })
            this.props.onReload();
        }).catch((err) => {
            NotistackEnqueueSnackbar("Error updating Service Quote Part", { variant: "error" });
            this.setState({
                isUpdating: false,
            })
        })
    }

    /**
     * Render the Update Service Quote Part Dialog
     */
    renderInternal() {
        return (
            <DialogUpdateServiceQuotePart
                open={this.state.dialogUpdateServiceQuotePartOpen}
                onClose={() => {
                    this.setState({
                        dialogUpdateServiceQuotePartOpen: false,
                        serviceQuoteParts: [],
                    })
                }}
                onConfirm={(serviceQuoteParts) => this.handleOnSaveServiceQuotePartChanges(serviceQuoteParts)}
                serviceQuoteParts={this.state.serviceQuoteParts}
                loading={this.state.isUpdating}
            />
        )
    }

    /**
     * Handle clicking on a grid row.
     */
    handleClickRow(clickedRow) {
        const allServiceQuotePartsPerOrder = this.props.rows.filter((row) => {
            return row["service_quote_part.order_activity_id"] === clickedRow["service_quote_part.order_activity_id"]
        })

        api.send("POST", `/serviceQuotePart/getByOrderActivityId/`, { orderActivityId: clickedRow["service_quote_part.order_activity_id"] })
            .then((serviceQuoteParts) => {
                 serviceQuoteParts.map((serviceQuotePart) => {
                    const servicePart = Object.values(this.context.servicePartCatalogsIndexed).find(
                        (servicePart) => servicePart.id === serviceQuotePart.servicePartCatalogId
                    );
                    
                    serviceQuotePart.partName = servicePart.description;
                });
 
 
                this.setState({
                    serviceQuoteParts: serviceQuoteParts,
                    dialogUpdateServiceQuotePartOpen: true,
                    serviceQuotePartsOriginal: allServiceQuotePartsPerOrder,
                });
            })
            .catch((err) => {
                console.error("Error fetching service quote parts:", err);
            });
    }
}