import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";

window.salesPilot = {
    meta: {
        version: "5.6.12",
        environment: null
    },
    dataGridScroll: {}, // You don't want to know what this is for...
    checkForUpdateInterval: null, // setInterval result to avoid adding duplicates
    checkForInactivityInterval: null, // setInterval result to avoid adding duplicates
};

// Determine the environment.
const url = new URL(window.location.href);
let environment;

switch(url.hostname) {
    case 'localhost':
        environment = 'development';
        break;
    case 'test1.salespilot.com':
        environment = 'test1';
        break;
    case 'test2.salespilot.com':
        environment = 'test2';
        break;
    case 'test3.salespilot.com':
        environment = 'test3';
        break;
    case 'demo.salespilot.com':
        environment = 'demo';
        break;
    case 'corkmedical.salespilot.com':
        environment = 'corkmedical';
        break;
    case 'www.salespilot.com':
    case 'salespilot.com':
        environment = 'production';
        break;
    default:
    // This will create on the fly in Sentry and we can address it there.
        environment = 'unknown';
        break;
}

window.salesPilot.meta.environment = environment;

/**
 * Initialize Sentry for error logging.
 *
 * https://docs.sentry.io
 */
if (environment !== 'development') {
    Sentry.init({
        dsn: "https://77734077368b644ce77f26bc9f55765d@o4506870707257344.ingest.us.sentry.io/4506870740221952",
        environment: environment,
        release: window.salesPilot.meta.version,
        integrations: [
            /**
             * The BrowserTracing integration sets up automatic performance
             * monitoring for your frontend applications. It captures
             * transactions and spans from the browser and sends them to Sentry.
             */
            Sentry.browserTracingIntegration(),

            /**
             * Session Replay helps you get to the root cause of an error or
             * latency issue faster by providing you with a video-like
             * reproduction of what was happening in the user's browser before,
             * during, and after the issue.
             *
             * NOTE: Blocking all of this data for now until self-hosted version
             * is set up.
             *
             * NOTE 2: Dropped mutationLimit from 10k (default) to 5k. This is
             * causing the add service part modal to take forever to open. The
             * length it takes depends on how many parts there are, so on UAT
             * it's fine, on live it takes minutes. We are also stopping the
             * replay on that component, so the mutationLimit change is
             * redundant but should help with performance generally anyways.
             */
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
                mutationLimit: 5000,
                maskFn: (text) => '█'.repeat(text.length)
            }),
        ],

        /**
         * A number between 0 and 1, controlling the percentage chance a given
         * transaction will be sent to Sentry. (0 represents 0% while 1
         * represents 100%.) Applies equally to all transactions created in the
         * app.
         */
        tracesSampleRate: 0.1,

        /**
         * When a user loads a page, a decision is made whether to sample it or
         * not for replay.
         */
        replaysSessionSampleRate: 0,

        /**
         * When an error occurs, a decision is made whether to sample it or not
         * for replay.
         */
        replaysOnErrorSampleRate: 0.1,

        /**
         * Specific errors to ignore.
         */
        ignoreErrors: [
            /**
             * Ignores the following errors from the PayPal Honey browser
             * extension.
             */
            "Unexpected token 'h', \"honey:vimGenerator:*\" is not valid JSON",
            "Unexpected token 'h', \"honey:core-sdk:*\" is not valid JSON",

            /**
             * Ignores client-side errors usually caused by local Internet
             * connection issues.
             */
            "Failed to fetch", // Chrome
            "NetworkError when attempting to fetch resource", // Firefox
        ],
    });
}

ReactDOM.render(<App />, document.getElementById("root"));
